import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import deleteIcon from '../../images/deleteIcon.png';
import { getAddresses, deleteWhitelistSingle } from '../../services/saleServices';
import { formatWalletAddress } from '../../utils/helpers';
import { successHandler } from '../../utils/toasts';
import { CircularProgress } from '@mui/material';

function BasicTable({ flag, setFlag, setLoad }) {
  const [rows, setRows] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAddresses({
          projectId: Number(localStorage.getItem('projectId')),
        });
        const data = response.data.data;
        console.log('data========>', data);
        setRows(data);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    }
    fetchData();
  }, [flag]);

  const handleDelete = async (address) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [address]: true,
    }));
    try {
      await deleteWhitelistSingle({
        projectId: Number(localStorage.getItem('projectId')),
        addressesToDelete: [address],
      });
      successHandler('User deleted');
      setFlag((prevFlag) => !prevFlag);
    } catch (error) {
      console.log(error);
    }
    setLoadingStates((prevState) => ({
      ...prevState,
      [address]: false,
    }));
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ backgroundColor: '#000000', color: '#ffffff', marginBottom: '5%' }}
    >
      <Table sx={{ borderCollapse: 'collapse' }} aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                backgroundColor: '#F59A6D12',
                border: '1px solid #F36E231A',
                borderBottom: '1px solid transparent',
                '&:last-child td, &:last-child th': { border: 0 }, // Remove border from last row
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: '#fff',
                  borderTop: '1px solid #F36E231A',
                  borderBottom: 0,
                }}
              >
                {formatWalletAddress(row.userWalletAddress, 20, 20)}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: '#fff',
                  borderTop: '1px solid #F36E231A',
                  borderBottom: 0,
                }}
              >
                {loadingStates[row.userWalletAddress] ? (
                  <CircularProgress size={20} sx={{ color: '#992A2A80' }} />
                ) : (
                  <img
                    src={deleteIcon}
                    alt="delete"
                    onClick={() => handleDelete(row.userWalletAddress)}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BasicTable;
