import { React, useState, useEffect } from 'react'
import HeadNav from '../components/navbar/HeadNav'
import Grid from '@mui/material/Grid'
import AdminProjectDetails from '../components/AdminProjectDetails'
import FootNav from '../components/navbar/FootNav'
import SaleManagement from '../components/saleManagement/SaleManagement'
import AddWhitelist from '../components/addWhitelist/AddWhitelist'
import AirdropTokens from '../components/airdropTokens/AirdropTokens'
import SaleEnded from '../components/saleEnded/SaleEnded'
import SaleLive from '../components/saleLive/SaleLive'
import ProjNotVerified from './ProjNotVerified'
import ProjLocked from './ProjLocked'
// import AdminNotVerified from './AdminNotVerified'
import Typography from '@mui/material/Typography'
// import { useWallet } from '../context/WalletProvider'
import { getProject, getProjectByUser } from '../services/saleServices'
import httpcommon from '../httpcommon'
import { useLocation } from 'react-router-dom'

const AdminHome = () => {
  const [salePhase, setSalePhase] = useState(false)
  const [load, setLoad] = useState()
  const verified = localStorage.getItem('verified')
  const [rerender, setRerender] = useState()
  const isAdmin = localStorage.getItem('adminToken') ? true : false
  const walletAddress = localStorage.getItem('userWalletAddress')
  const { state } = useLocation()

  useEffect(() => {

    async function fetchData() {
      setLoad(true)
      try {
        let res = await getProject({
          projectId: state.id,
        })
        setSalePhase(res.data.data.SalePhase)
        console.log('dataaaaaaaaaaaaaaaaaa', res.data.data)

        setLoad(false)
        // localStorage.setItem('projectId', res.data.data.ProjectID)
        // localStorage.setItem('verified', res.data.data.verified)
        // localStorage.setItem('salePhase', res.data.data.SalePhase)
      } catch (e) {
        // localStorage.setItem('salePhase', 'nosale')

        // setSalePhase('nosale')
        console.log(e)
        setLoad(false)
      }
    }
    fetchData()
  }, [walletAddress, rerender])

  return (
    <>
      <HeadNav>
        {load ? (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6">Loading...</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <AdminProjectDetails />
            </Grid>
            <Grid item xs={5}>

              {verified === 'false' ? (<ProjNotVerified />) :
                salePhase === 'public' ? <SaleLive setRerender={setRerender} /> :
                  salePhase === 'whitelist' ? <AddWhitelist setRerender={setRerender} /> :
                    salePhase === 'paused' ? <ProjLocked setRerender={setRerender} /> :
                      salePhase === 'null' || salePhase === null ? <SaleManagement setRerender={setRerender} /> :
                        salePhase === 'airdrop' || salePhase === 'withdraw' ? <AirdropTokens setRerender={setRerender} /> :
                          salePhase === 'ended' ? <SaleEnded setRerender={setRerender} /> :
                            <SaleManagement setRerender={setRerender} />

              }
            </Grid>
          </Grid>

          //   <Grid container spacing={2}>
          //     {walletAddress === null ? 'wallet address null' : salePhase === 'nosale' ? (
          //       // <Grid item xs={5}>
          //       <NotProjAdmin />
          //     ) : salePhase === 'paused' ? (
          //       // <Grid item xs={5}>
          //       <ProjLocked />
          //     ): (
          //       <>
          //         <Grid item xs={7}>
          //           <ProjectDetails />
          //         </Grid>
          //         {verified === 'true' ? (
          //           salePhase === null ? (
          //             <Grid item xs={5}>
          //               <SaleManagement />
          //             </Grid>
          //           ) : salePhase === 'public' ? (
          //             <Grid item xs={5}>
          //               <SaleLive />
          //             </Grid>
          //           ) : salePhase === 'whitelist' ? (
          //             <Grid item xs={5}>
          //               <AddWhitelist />
          //             </Grid>
          //           ) : salePhase === 'airdrop' || salePhase === 'withdraw' ? (
          //             <Grid item xs={5}>
          //               <AirdropTokens />
          //             </Grid>
          //           ) : salePhase === 'ended' ? (
          //             <Grid item xs={5}>
          //               <SaleEnded />
          //             </Grid>
          //           ) : (
          //             <Grid item xs={5}>
          //               <SaleManagement />
          //             </Grid>
          //           )
          //         ) : (
          //           // </Grid>
          //           <Grid
          //             item
          //             xs={5}
          //             sx={{
          //               height: '100vh',
          //               display: 'flex',
          //               justifyContent: 'center',
          //               alignItems: 'center',
          //             }}
          //           >
          //             <Typography variant="h6">
          //               Please wait for verification of the project from admin!
          //             </Typography>
          //           </Grid>
          //         )}
          //       </>
          //     )}
          //   </Grid>
        )}
      </HeadNav>
      <FootNav />
    </>
  )
}

export default AdminHome
