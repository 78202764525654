import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  datePicker: {
    backgroundColor: '#0B0D15',
    border: '1px solid #161D2E !important',
    borderRadius: '10px',
    color: '#161D2E !important',
    input: { color: '#566077' },
    '& .MuiOutlinedInput-root': {
      color: '#566077',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#161D2E',
        // borderWidth: '2px',
        color: '#566077',
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#566077',
          borderWidth: '2px',
          color: '#566077 !important',
        },
      },
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: '#566077',
      '&.Mui-focused': {
        color: '#566077 !important',
        borderColor: '#566077',
      },
    },
    '& .MuiInputLabel-outlined': {
      color: '#566077',
      '&.Mui-focused': {
        color: '#566077',
        borderColor: '#566077',
      },
    },
    '&.Mui-focused': {
      color: '#566077',
    },
    '& .MuiButtonBase-root': {
      color: '#566077',
    },
  },

  textField: {
    input: { color: '#566077', padding:'0' },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#566077',
    },
    '& .MuiOutlinedInput-root': {
      color: '#566077',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#161D2E',
        borderWidth: '2px',
        color: '#566077',
      },
      '& .Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#566077',
          borderWidth: '2px',
          color: '#566077 !important',
        },
      },
    },
    '& .MuiInputLabel-outlined': {
      color: '#566077',
      '&.Mui-focused': {
        color: '#566077',
        borderColor: '#161D2E',
      },
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: '#566077',
      '&.Mui-focused': {
        color: '#566077 !important',
        borderColor: '#566077',
      },
    },
    '&.Mui-focused': {
      color: '#566077',
      borderColor: '#566077',
    },
  },

  formLabel: {
    color: '#566077 !important',
    '&.Mui-focused': {
      color: '#566077',
    },
  },

  select: {
    color: '#566077',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#161D2E', // Border color
      borderWidth: '2px',
      '& .MuiSelect-root': {
        color: '#566077', // Selected value color
      },
    },
    '&:focus-within .MuiOutlinedInput-notchedOutline': {
      borderColor: '#161D2E', // Border color on focus
      borderWidth: '2px',
    },
    '& .MuiSvgIcon-root': {
      color: '#566077', // Selected value color
    },
    '& .MuiSelect-root': {
      color: '#566077', // Text color
      borderColor: '#161D2E',
      border: '1px solid #161D2E',
      '&:focus': {
        backgroundColor: 'transparent', // Override default focused background
        border: '1px solid #161D2E',
      },
    },
    '& .MuiSelect-select': {
      color: '#566077',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiSelect-outlined': {
      color: '#566077',
      '&:focus': {
        backgroundColor: 'transparent',
        borderColor: '#161D2E',
      },
    },
    '& .MuiInputBase-input': {
      color: '#566077',
      '&:focus': {
        backgroundColor: 'transparent',
        borderColor: '#161D2E',
      },
    },
    '& .MuiOutlinedInput-input': {
      color: '#566077',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#161D2E',
    },
    '& .MuiOutlinedInput-root': {
      color: '#566077',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#161D2E',
        borderWidth: '2px',
        color: '#566077',
      },
      '& .Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#161D2E',
          borderWidth: '2px',
          color: '#566077 !important',
        },
      },
    },
    '& .MuiInputLabel-outlined': {
      color: '#566077',
      '&.Mui-focused': {
        color: '#566077',
        borderColor: '#566077',
      },
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: '#566077',
      '&.Mui-focused': {
        color: '#566077 !important',
        borderColor: '#161D2E',
      },
    },
    '&.Mui-focused': {
      color: '#566077',
    },
  },

  successChip: {
    background: '#287B5330',
    border: '1px solid #287B53',
    fontFamily: 'Exo 2',
    color: '#287B53',
    '& .MuiChip-label': {
      fontFamily: 'Exo 2 !important',
    },
  },

  remainingChip: {
    background: '#992A2A30',
    border: '1px solid #992A2A',
    fontFamily: 'Exo 2',
    color: '#992A2A',
  },
})

export default useStyles
