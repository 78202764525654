import './App.css'
import { useWallet, WalletProvider } from '../src/context/WalletProvider'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MainRouter from './router/MainRouter'
import { useEffect, useState } from 'react'
import FootNav from './components/navbar/FootNav'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import httpcommon from './httpcommon'

const theme = createTheme({
  palette: {
    primary: {
      main: '#566077',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Exo 2 !important',
      textTransform: 'none',
      // fontSize: 16,
    },
  },
})

function App() {
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  // const { walletAddress, status, setValue } = useWallet();

  useEffect(() => {
    httpcommon.defaults.headers.common['ManagerToken'] = localStorage.getItem('managerToken');
    console.log(localStorage.getItem('managerToken'))
  },[])

  return (
    <>
      {/* <farmer.Provider value={context}> */}
      <ThemeProvider theme={theme}>
        {/* <div style={{ fontFamily: 'Exo 2' }}> */}
        <WalletProvider>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Router>
            {/* <HeadNav /> */}
            <MainRouter />
            {/* <FootNav /> */}
          </Router>
          {/* </farmer.Provider> */}
        </WalletProvider>
        {/* </div> */}
      </ThemeProvider>
    </>
  )
}

export default App

// Import dependencies
// import {useWallet, WalletProvider} from '../src/context/WalletProvider'

// // Sample usage
// const App = () => {
//   // Using the useWallet hook
//   const { walletAddress, status, setValue } = useWallet();

//   // Example logic using walletAddress, status, and setValue
//   // Note: This is just a placeholder, you should replace it with your actual application logic
//   const handleClick = () => {
//     setValue("exampleKey", "exampleValue");
//   };

//   return (
//     <div>
//       <h1>Welcome to My App</h1>
//       <p>Wallet Address: {walletAddress}</p>
//       <p>Status: {status}</p>
//       <button onClick={handleClick}>Update Value</button>
//     </div>
//   );
// };

// // Wrap your app with the WalletProvider
// const WrappedApp = () => {
//   return (
//     <WalletProvider>
//       <App />
//     </WalletProvider>
//   );
// };

// // Export your wrapped app
// export default WrappedApp
