import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SaleManagement from './saleManagement/SaleManagement';
import AddWhitelist from './addWhitelist/AddWhitelist';
import SaleLive from './saleLive/SaleLive';
import AirdropTokens from './airdropTokens/AirdropTokens';
import SaleEnded from '../components/saleEnded/SaleEnded'
import { Grid, StepContent } from '@mui/material';
import Table from './saleLive/Table'



export default function RightSide({ salePhase, setRerender, data }) {
  const steps = [{
    text: 'Sale Management',
    component: <SaleManagement setRerender={setRerender} />
  },
  {
    text: 'Add to WhiteList',
    component: <AddWhitelist setRerender={setRerender} />
  },
  {
    text: 'Sale Live',
    component: <SaleLive setRerender={setRerender} />
  }, {
    text: 'AirDrop Tokens',
    component: <AirdropTokens setRerender={setRerender} data={data} />
  },
  {
    text: 'Withdraw',
    component: <AirdropTokens setRerender={setRerender} data={data} />
  },
  {
    text: 'Sale Ended',
    component: <SaleEnded setRerender={setRerender} />
  },
  ];
  
  const [activeStep, setActiveStep] = React.useState(-1);
  const [skipped, setSkipped] = React.useState(new Set());

  React.useEffect(() => {
    const act = salePhase === null ? 0 : salePhase === 'whitelist' ? 1 : salePhase === 'public' ? 2 : salePhase === 'airdrop' ? 3 : salePhase === 'withdraw' ? 4 : 6
    setActiveStep(act)
  }, [salePhase])

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper sx={{

        '& .Mui-completed': { color: '#287B5366 !important', bgcolor: 'transparent', border: 'none' },
        '& .Mui-active': { color: '#FA7C0A24 !important' }

      }} activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography sx={{ color: '#ffffff5c', margin: '0', padding: '0' }} variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}><h2 style={{ color: 'white', margin: '0', padding: '0' }}>{label.text}</h2></StepLabel>
              <StepContent>
                {label.component}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
     {activeStep === 6 && <Grid container spacing={2} sx={{  paddingRight: 5 }}>
       
       <Grid item xs={12}>
         <Table />
       </Grid>
     </Grid>}
    </Box>
  );
}