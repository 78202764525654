import { React, useState } from 'react'
import { Typography, Grid, Button, Tabs, Tab, Box, CircularProgress } from '@mui/material'
import '../../utils/custom.css'
import PropTypes from 'prop-types'
import UploadCSV from './UploadCSV'
import UploadManually from './UploadManually'
import AddressTable from './AddressTable'
import { confirmAllWhitelist } from '../../services/saleServices'
import { useNavigate } from 'react-router-dom'
import { successHandler, errorHandler } from '../../utils/toasts'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const AddWhitelist = ({ setRerender }) => {
  const [uploadState, setUploadState] = useState('manual')
  const [value, setValue] = useState(0)
  const [flag, setFlag] = useState(false)
  const [load, setLoad] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  // var rerender = localStorage.getItem('rerender')

  const handleConfirmWhitelist = async () => {
    setLoading(true)
    // const handleAirdrop = async () => {
    const res = await confirmAllWhitelist({
      projectId: Number(localStorage.getItem('projectId')),
    })
    setRerender('whitelist confirmed')
    successHandler('Whitelist confirmed!')
    localStorage.setItem("salePhase", "public")

    setLoading(false)
    // }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <div >
      <Grid container RowSpacing={2}>
      <Grid item xs={0.5}></Grid>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  color: 'white',
                  padding:'0',
                  '& .Mui-selected': {
                    backgroundImage:
                      'linear-gradient(90.35deg, rgba(245, 154, 109, 0.25) -10.25%, rgba(214, 78, 94, 0.25) 59.57%)',
                    borderRadius: '8px 8px 0px 0px',
                    color: '#ffffff !important',
                  },
                  '& .MuiTabs-indicator': {
                    backgroundImage:
                      'linear-gradient(90.35deg, rgba(245, 154, 109) -10.25%, rgba(214, 78, 94) 59.57%)',
                  },
                  tabItemContainerStyle: '',
                }}
              >
                <Tab   sx={{ color: 'white' }} label="CSV" {...a11yProps(0)} />
                <Tab sx={{ color: 'white' }} label="Manual" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Box>
          {/* {uploadState === 'csv' ? (
            <>
              <Button
                // className="button"
                sx={{
                  border: '1px solid',
                  borderImageSource:
                    'linear-gradient(90.35deg, #F59A6D -10.25%, #D64E5E 59.57%)',
                  background:
                    ' linear-gradient(90.35deg, rgba(245, 154, 109, 0.45) -10.25%, rgba(214, 78, 94, 0.45) 59.57%)',
                  fontStyle: 'Exo 2',
                  color: '#ffffff',
                  borderRadius: '8px 0px 0px 8px',
                }}
                // onClick={setUploadState('csv')}
              >
                CSV
              </Button>
              <Button
                sx={{
                  border: '1px solid #EE2C1B1A',
                  borderImageSource:
                    'linear-gradient(90.35deg, #F59A6D -10.25%, #D64E5E 59.57%)',
                  background:
                    ' linear-gradient(90.01deg, rgba(238, 44, 27, 0.05) 0.01%, rgba(243, 110, 35, 0.05) 99.99%)',
                  fontStyle: 'Exo 2',
                  color: '#ffffff',
                  borderRadius: '0px 8px 8px 0px',
                }}
                // onClick={setUploadState('manual')}
              >
                Manual
              </Button>
            </>
          ) : (
            <>
              <Button
                className="button"
                sx={{
                  border: '1px solid #EE2C1B1A',
                  borderImageSource:
                    'linear-gradient(90.35deg, #F59A6D -10.25%, #D64E5E 59.57%)',
                  background:
                    ' linear-gradient(90.01deg, rgba(238, 44, 27, 0.05) 0.01%, rgba(243, 110, 35, 0.05) 99.99%)',
                  fontStyle: 'Exo 2',
                  color: '#ffffff',
                  borderRadius: '8px 0px 0px 8px',
                }}
                onClick={setUploadState('csv')}
              >
                CSV
              </Button>
              <Button
                sx={{
                  border: '1px solid',
                  borderImageSource:
                    'linear-gradient(90.35deg, #F59A6D -10.25%, #D64E5E 59.57%)',
                  background:
                    ' linear-gradient(90.35deg, rgba(245, 154, 109, 0.45) -10.25%, rgba(214, 78, 94, 0.45) 59.57%)',
                  fontStyle: 'Exo 2',
                  color: '#ffffff',
                  borderRadius: '0px 8px 8px 0px',
                }}
                onClick={setUploadState('manual')}
              >
                Manual
              </Button>
            </>
          )} */}
        </Grid>
        <Grid item xs={12}>
          <CustomTabPanel  value={value} index={0}>
            <UploadCSV flag={flag} setFlag={setFlag} load={load} setLoad={setLoad} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <UploadManually flag={flag} setFlag={setFlag} load={load} setLoad={setLoad} />
          </CustomTabPanel>
        </Grid>
        <Box sx={{display:'flex', justifyContent:'center', width:'100%', padding:'0% 5%'}}>
        <AddressTable flag={flag} setFlag={setFlag} setLoad={setLoad}/>
        </Box>
        {/* <Grid item xs={12}><AddressTable /></Grid> */}
        {!loading ? <Grid item xs={12}>
          <Button
            className="button"
            sx={{
              fontFamily: 'exo 2',
              marginLeft: 3,
              width: '90%',
              borderRadius: '10px',
            }}
            onClick={handleConfirmWhitelist}
          >
            Move sale to public
          </Button>
        </Grid> : <Grid item xs={11.5}  style={{display:'flex', justifyContent:'center'}}>
      <div style={{backgroundImage: 'linear-gradient(90.35deg, #f59a6d -10.25%, #d64e5e 59.57%)', display:'flex', alignItems:'center', justifyContent:'center',borderRadius:'55%', padding:'1%'}}>
      <CircularProgress size={30}  sx={{ color:'white' }} />
      </div>
    </Grid>}
      </Grid>
    </div>
  )
}

export default AddWhitelist
