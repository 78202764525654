import { React, useState, useEffect } from 'react'
import { Grid, Button, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import Table from '../saleLive/Table'
import {
  airdropTokenCreate,
  getInscriptionStatus,
  airdropTokenSign,
  airdropTokenExecute,
  doTransaction,
  generateTransaction,
} from '../../services/saleServices'
import { successHandler, errorHandler } from '../../utils/toasts'
import {
  getDynamicFee
} from '../../services/saleServices'
import { useNavigate } from 'react-router-dom'

const AirdropTokens = () => {
  const [tokenExec, setTokenExec] = useState()
  const [publicKey, setPublicKey] = useState()
  const [display, setDisplay] = useState('none')
  const [feeRate, setFeeRate] = useState()
  const navigate = useNavigate()
  var salePhase = localStorage.getItem('salePhase')

  // const { walletAddress } = useWallet()

  useEffect(() => {
    async function fetchData() {
      console.log('here')
      let res = await window.unisat.getPublicKey()
      setPublicKey(res)
      const network = await window.unisat.getNetwork()
        const response = await getDynamicFee()
        const data = response.data
        setFeeRate(data.fee + 1)
    }

    fetchData()
  }, [])

  return (
    <div>
      <Grid container spacing={2} sx={{  paddingRight: 5 }}>
       
        <Grid item xs={12}>
          {/* <Table /> */}
        </Grid>
      </Grid>
    </div>
  )
}

export default AirdropTokens
