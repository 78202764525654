import React from 'react'
import HeadNav from '../components/navbar/HeadNav'
import {Grid, Typography} from '@mui/material'
import ProjectDetails from '../components/ProjectDetails'
import FootNav from '../components/navbar/FootNav'
import SaleLive from '../components/saleLive/SaleLive'

const SaleLivePage = () => {
  return (
    <>
      {/* <HeadNav> */}
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">You are not a project admin</Typography>
          </Grid>
        </Grid>
      {/* </HeadNav> */}
      {/* <FootNav /> */}
    </>
  )
}

export default SaleLivePage
