import httpcommon from '../httpcommon'
import axios from 'axios'

export const getProjectByUser = (data) => {
  return httpcommon.get(`project/getMyProject`)
}

export const getProject = (data) => {
  return httpcommon.post(`project/getProjectByIdAdmin`, data)
}

export const managerLoginRequest = (data) => {
  return httpcommon.post(`admin/manager-login-request`, data)
}

export const managerLoginSubmit = (data) => {
  return httpcommon.post(`admin/manager-login-submit`, data)
}

export const createSale = (data) => {
  return httpcommon.post(`sale/createSale`, data)
}

export const addAddress = (data) => {
  return httpcommon.post(`blockchain/addWhiteList`, data)
}

export const getAddresses = (data) => {
  return httpcommon.post(`sale/getAllAddress`, data)
}

export const uploadCSV = (data) => {
  console.log(data)
  return httpcommon.post(`blockchain/addWhiteListCSV`, data)
}

export const confirmAllWhitelist = (data) => {
  return httpcommon.post(`/blockchain/confirmAllWhitelist`, data)
}

export const deleteWhitelistSingle = (data) => {
  return httpcommon.post(`/blockchain/deleteWhitelistSingle`, data)
}

export const confirmAllSales = (data) => {
  return httpcommon.post(`/sale/confirmAllSales `, data)
}

export const airdropTokenCreate = (data) => {
  return httpcommon.post(`launch/airdropTokenCreate`, data)
}

export const getInscriptionStatus = (orderId) => {
  return httpcommon.get(`launch/getInscriptionStatus/${orderId}`)
}

export const tokenCreate = (projectId) => {
  console.log(projectId)
  return httpcommon.post(`launch/airdropTokenCreate`,{projectId: projectId})
}

export const tokenPaid = (data) => {
  return httpcommon.post('launch/airdropTokenPaid', data)
}

export const tokenSign = (projectId) => {
  console.log(projectId)
  return httpcommon.post('launch/airdropTokenSign', {projectId: projectId})
}

export const tokenExecute = (data) => {
  return httpcommon.post('launch/airdropTokenExecute', data)
}

export const airdropTokenSign = (data) => {
  return httpcommon.post(`launch/airdropTokenSign`, data)
}

export const airdropTokenExecute = (data) => {
  return httpcommon.post(`launch/airdropTokenExecute`, data)
}

export const generateTransaction = (data) => {
  return httpcommon.post(`blockchain/genTransaction`, data)
}

export const doTransaction = (data) => {
  return httpcommon.post(`blockchain/doTransaction`, data)
}

export const getDynamicFee = () => {
  return httpcommon.get(`blockchain/getFees`)
}


export const adminLogin = (data) => {
  return httpcommon.post(`admin/admin-login`, data)
}

export const getUnverifiedProjects = () => {
  return httpcommon.get(`project/getUnverifiedProject?page=1&pageSize=200`, {
    headers: {
      AdminToken: `${localStorage.getItem('adminToken')}`,
    },
  })
}

export const getProjects = () => {
  return httpcommon.get(`project/getProject?page=1&pageSize=200`)
}

export const verifyProject = (data) => {
  return httpcommon.post(`project/updateProjectStatus`, data, {
    headers: {
      AdminToken: `${localStorage.getItem('adminToken')}`,
    },
  })
}

export const lockProject = (data) => {
  return httpcommon.post(`sale/lockToggleSale`, data, {
    headers: {
      AdminToken: `${localStorage.getItem('adminToken')}`,
    },
  })
}

export const getSalesProgress = (data) => {
  return httpcommon.post(`blockchain/getSalesProgress`, data)
}

export const getProjectAdmin = (data) => {
  return httpcommon.post(`project/getProjectById`, data)
}

