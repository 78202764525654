import { React, useContext, useState } from 'react'
import { Box, Toolbar, Grid } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import logo from '../../images/logo.png'
import wallet from '../../images/wallet.png'
import '../../utils/custom.css'
import { WalletProvider, useWallet } from '../../context/WalletProvider'
import { disconnect } from 'process'
import Tooltip from '@mui/material/Tooltip'
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  setToLocalStorage,
} from '../../utils/storage'
import { formatWalletAddress } from '../../utils/helpers'
import { useNavigate } from 'react-router-dom'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import { getProjectByUser, managerLoginRequest, managerLoginSubmit } from '../../services/saleServices'
import axios from 'axios'
import httpcommon from '../../httpcommon'
import { errorHandler } from '../../utils/toasts'

const styles = {
  navbar: {
    backgroundColor: '#000000',
  },
}

const Navbar = (props) => {
  const { children } = props
  // const {walletAddress, setWalletAddress} = useContext(WalletProvider)
  // const [address, setAddress] = useState('')
  const [load, setLoad] = useState(false)
  const { walletAddress, setWalletAddress, setValue } = useWallet()
  const navigate = useNavigate()

  function isMainnetUrl() {
    if (typeof window !== 'undefined') {
      return (
        !window.location.href.includes('localhost') &&
        !window.location.href.includes('testnet')
      )
    }
  }

  const isMainnet = isMainnetUrl()

  function isValidTaprootAddress(address) {
    const taprootRegex = isMainnet
      ? /^bc1[0-9a-zA-Z]{59}$/
      : /^tb1[0-9a-zA-Z]{59}$/
    return taprootRegex.test(address)
  }

  const connectUniset = async () => {
    setLoad(true)
    try {
      let accounts = await window.unisat.requestAccounts()
      const [address] = await window.unisat.getAccounts()
      if (isValidTaprootAddress(address)) {
        // If the address is valid Taproot address

        try {
          let res1 = await managerLoginRequest({
            userWalletAddress: address,
          })
          console.log(res1)
          try {
            let resS = await window.unisat.signMessage(res1?.data?.message);
            console.log(resS)
            let res2 = await managerLoginSubmit({
              userWalletAddress: address,
              signature: resS
            })
            httpcommon.defaults.headers.common['ManagerToken'] = res2.data.managerToken;
            console.log(res2)
            // deleteFromLocalStorage('adminToken')
            localStorage.setItem("managerToken" , res2.data.managerToken)
            setValue('account', address)
            setWalletAddress(address)
            // setLoad(false)
            let res = await getProjectByUser({
              userWalletAddress: address,
            })
            // setProject(res.data.data)
            // console.log(res.data.data)
            localStorage.setItem('walletAddress', address)
            localStorage.setItem('projectId', res.data.data.ProjectID)
            localStorage.setItem('verified', res.data.data.verified)
            localStorage.setItem('salePhase', res.data.data.SalePhase)
            setLoad(false)
            navigate('/')
          } catch (e) {
            console.log(e);
            errorHandler(e.message)
            setLoad(false)
          }
        } catch (e) {
          console.log(e);
          errorHandler(e.message)
          setLoad(false)
        }

      } else {
        // If the address is not a valid Taproot address
        console.log('Invalid Taproot address')
        // showToast(
        //   'Please use a Taproot address, make sure to use the correct network',
        //   TOAST_TYPES.ERROR
        // )
      }
    } catch (e) {
      console.log('connect failed')
      errorHandler(e.message)
    }
  }

  const disconnect = async () => {
    try {
      deleteFromLocalStorage('account')
      deleteFromLocalStorage('walletAddress')
      deleteFromLocalStorage('managerToken')
      deleteFromLocalStorage('status')
      // setValue('account', null)
      delete httpcommon.defaults.headers.common['ManagerToken'];
      navigate('/')
      window.location.reload()
    } catch (error) { }
  }

  console.log(walletAddress)

  return (
    <Box style={styles.navbar} component="main" sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ p: 3, backgroundColor: '#000000' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => navigate('/')}
          >
            <img src={logo} alt="logo" onClick={() => navigate('/')} />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {/* News */}
          </Typography>
          {/* <Button
            className="button"
            sx={{ borderRadius: '67px', fontStyle: 'Exo 2' }}
            onClick={connectUniset}
          >
            <img src={wallet} alt="wallet" style={{ marginRight: '1rem' }} />
            <Typography variant="body1">Connect Wallet</Typography>
          </Button> */}
          <AdminPanelSettingsIcon
            sx={{
              color: '#fff',
              fontSize: '2.5em',
              marginRight: '0.5em',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/adminlogin')}
          />
          {walletAddress !== null ? (
            <>
              {/* <Link className="relative" href="/notifications">
                <BellIcon className="text-gray-dull hover:text-gray-dull/65" />
                <div className="absolute right-0 top-0 h-2 w-2 rounded-full border border-gray-dull bg-[#EA3434]"></div>
              </Link>
              <Link href="/profile">
                <PersonIcon className="text-gray-dull hover:text-gray-dull/65" />
              </Link> */}
              <Tooltip title="Click to disconnect">
                <Button
                  variant="gradient"
                  className="button"
                  sx={{ borderRadius: '67px', fontStyle: 'Exo 2' }}
                  size="xl-icon"
                  onClick={disconnect}
                >
                  {/* <span className="hidden group-hover:block">Disconnect</span> */}
                  <span className="hidden group-hover:hidden sm:block">
                    {formatWalletAddress(walletAddress, 6, 3)}
                  </span>
                  {/* <span className="block group-hover:hidden sm:hidden">
                  {formatWalletAddress(address, 3, 3)}
                </span> */}
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ paddingLeft: '0.5em' }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.5308 0.330301C10.5345 -0.1101 8.46552 -0.1101 6.46917 0.3303L6.05155 0.422427C3.26343 1.03749 1.06534 3.17136 0.376983 5.93119C-0.125661 7.94645 -0.125661 10.0536 0.376983 12.0688C1.06534 14.8286 3.26343 16.9625 6.05155 17.5776L6.46917 17.6697C8.46552 18.1101 10.5345 18.1101 12.5308 17.6697L12.9484 17.5776C15.7366 16.9625 17.9347 14.8286 18.623 12.0688C19.1257 10.0535 19.1257 7.94645 18.623 5.9312C17.9347 3.17136 15.7366 1.03749 12.9484 0.422426L12.5308 0.330301ZM15.4433 6.80778C15.8203 6.74389 16.202 6.72114 16.5807 6.73871C16.9968 6.75801 17.3243 7.08253 17.3802 7.49359C17.5163 8.49327 17.5163 9.50673 17.3802 10.5064C17.3243 10.9175 16.9968 11.242 16.5807 11.2613C16.202 11.2789 15.8203 11.2561 15.4433 11.1922L15.3694 11.1797C14.3874 11.0133 13.6287 10.3507 13.3722 9.51589C13.2684 9.1783 13.2684 8.8217 13.3722 8.48411C13.6287 7.64926 14.3874 6.98674 15.3694 6.82032L15.4433 6.80778ZM4.84559 5.97732C4.84559 5.64344 5.11739 5.37278 5.45269 5.37278L9.50002 5.37278C9.83531 5.37278 10.1071 5.64344 10.1071 5.97732C10.1071 6.3112 9.83531 6.58186 9.50002 6.58186H5.45269C5.11739 6.58186 4.84559 6.3112 4.84559 5.97732Z"
                      fill="white"
                    />
                  </svg>
                </Button>
              </Tooltip>
            </>
          ) : (
            <Button
              variant="gradient"
              className="button"
              sx={{ borderRadius: '67px', fontStyle: 'Exo 2' }}
              size="xl-icon"
              onClick={connectUniset}
            >
              Connect Wallet
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ paddingLeft: '0.5em' }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.5308 0.330301C10.5345 -0.1101 8.46552 -0.1101 6.46917 0.3303L6.05155 0.422427C3.26343 1.03749 1.06534 3.17136 0.376983 5.93119C-0.125661 7.94645 -0.125661 10.0536 0.376983 12.0688C1.06534 14.8286 3.26343 16.9625 6.05155 17.5776L6.46917 17.6697C8.46552 18.1101 10.5345 18.1101 12.5308 17.6697L12.9484 17.5776C15.7366 16.9625 17.9347 14.8286 18.623 12.0688C19.1257 10.0535 19.1257 7.94645 18.623 5.9312C17.9347 3.17136 15.7366 1.03749 12.9484 0.422426L12.5308 0.330301ZM15.4433 6.80778C15.8203 6.74389 16.202 6.72114 16.5807 6.73871C16.9968 6.75801 17.3243 7.08253 17.3802 7.49359C17.5163 8.49327 17.5163 9.50673 17.3802 10.5064C17.3243 10.9175 16.9968 11.242 16.5807 11.2613C16.202 11.2789 15.8203 11.2561 15.4433 11.1922L15.3694 11.1797C14.3874 11.0133 13.6287 10.3507 13.3722 9.51589C13.2684 9.1783 13.2684 8.8217 13.3722 8.48411C13.6287 7.64926 14.3874 6.98674 15.3694 6.82032L15.4433 6.80778ZM4.84559 5.97732C4.84559 5.64344 5.11739 5.37278 5.45269 5.37278L9.50002 5.37278C9.83531 5.37278 10.1071 5.64344 10.1071 5.97732C10.1071 6.3112 9.83531 6.58186 9.50002 6.58186H5.45269C5.11739 6.58186 4.84559 6.3112 4.84559 5.97732Z"
                  fill="white"
                />
              </svg>
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {children}
    </Box>
  )
}

export default Navbar
