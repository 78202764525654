import React, { useEffect } from 'react'
import HeadNav from '../components/navbar/HeadNav'
import FootNav from '../components/navbar/FootNav'
import ProjectsKanban from '../components/admin/ProjectsKanban'
import httpcommon from '../httpcommon'

const AdminDashboard = () => {
  useEffect(() => {
    httpcommon.defaults.headers.common['AdminToken'] = localStorage.getItem('adminToken')
  },[])
  return (
    <>
      <HeadNav>
        {/* <Grid container spacing={2}>
          <Grid item xs={7}>
            <ProjectDetails />
          </Grid>
          <Grid item xs={5}>
            <SaleLive />
          </Grid>
        </Grid> */}
        <ProjectsKanban />
      </HeadNav>
      <FootNav />
    </>
  )
}

export default AdminDashboard
