import { React, useEffect, useState } from 'react'
import { Grid, Button, Typography, CircularProgress } from '@mui/material'
import Table from './Table'
import { useNavigate } from 'react-router'
import { confirmAllSales, getSalesProgress } from '../../services/saleServices'
import { errorHandler, successHandler } from '../../utils/toasts'

const SaleLive = ({ setRerender }) => {
  const navigate = useNavigate()
  const [load, setLoad] = useState(false)
  const [saleProgress, setSaleProgress] = useState(0)

  useEffect(() => {
    async function fetchData() {
      try {
        let res = await getSalesProgress({
          projectId: Number(localStorage.getItem('projectId')),
        })
        setSaleProgress(res.data.totalBalance)
        console.log(res.data)
      } catch (e) {
        console.log(e)
      }
    }
    const intervalId = setInterval(() => {
      fetchData()
    }, 1000 * 5) // in milliseconds
    return () => clearInterval(intervalId)
    // fetchData()
  }, [])
console.log(saleProgress)
  const handleAirdrop = async () => {
    setLoad(true)
    try {
      const res = await confirmAllSales({
        projectId: Number(localStorage.getItem('projectId')),
      })
      setRerender('moving to airdrop')
      successHandler('Sales confirmed!')
      localStorage.setItem("salePhase", "airdrop")
    } catch (error) {
      console.log(error)
      errorHandler('Error while confirming all sales')
      // navigate('/airdropTokens')
    }
    setLoad(false)
  }
  return (
    <div>
      <Grid container spacing={2} sx={{  paddingRight: 5 }}>
        
        <Grid item xs={9}>
          <Button
            sx={{
              fontFamily: 'Exo 2',
              // height: '60%',
              color: '#fff',
              marginRight: 3,
              // width: '80%',
              borderRadius: '10px',
              border: '1px solid #566077',
            }}
          >
            FUND Raised {saleProgress ? (saleProgress / 100000000) : 0} BTC
          </Button>
        </Grid>
        {!load ? <Grid item xs={3}>
          <Button
            className="button"
            sx={{
              fontFamily: 'exo 2',
              marginLeft: 3,
              // width: '80%',
              borderRadius: '10px',
            }}
            onClick={handleAirdrop}
          >
            Airdrop
          </Button>
        </Grid>
          : <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ backgroundImage: 'linear-gradient(90.35deg, #f59a6d -10.25%, #d64e5e 59.57%)', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '55%', padding: '4%' }}>
              <CircularProgress size={30} sx={{ color: 'white' }} />
            </div>
          </Grid>}
        <Grid item xs={12}>
          <Table />
        </Grid>
      </Grid>
    </div>
  )
}

export default SaleLive
