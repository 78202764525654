import React from 'react'
import HeadNav from '../components/navbar/HeadNav'
import {Grid, Typography} from '@mui/material'
import ProjectDetails from '../components/ProjectDetails'
import FootNav from '../components/navbar/FootNav'
import SaleLive from '../components/saleLive/SaleLive'
import {useNavigate} from 'react-router-dom'

const ProjNotVerified = () => {
  const navigate = useNavigate()
  return (
    <>
      {/* <HeadNav> */}
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              height: '90vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Typography variant="h6" sx={{mb:1}}>Please verify the project before you edit!</Typography>
            <Typography variant="body1" onClick={()=> navigate('/admindash')} sx={{cursor: 'pointer'}}><u>Back to all projects</u></Typography>
          </Grid>
        </Grid>
      {/* </HeadNav> */}
      {/* <FootNav /> */}
    </>
  )
}

export default ProjNotVerified
