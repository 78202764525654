import { React, useState, useEffect } from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import '../../utils/custom.css'
import useStyles from '../../utils/styles'
import {
  TextField,
  Typography,
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  Checkbox,
  CircularProgress,
} from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { useNavigate } from 'react-router'
import { createSale } from '../../services/saleServices'
import moment from 'moment'
import { successHandler, errorHandler } from '../../utils/toasts'

const SaleManagement = ({ setRerender }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [saleType, setSaleType] = useState('')
  const [salePhase, setSalePhase] = useState('whitelist')
  const [load, setLoad] = useState(false)

  const [json, setJson] = useState({
    Type: '',
    SaleSupply: 0,
    SalePrice: 0, //in BTC
    UserMaxAllocation: 0, //in BTC
    AllowlistSubmissionStarted: '', // Add UTC time, example: 2024-02-23T21:50:00.000Z
    AllowlistSubmissionEnded: '', // Add UTC time, example: 2024-02-23T21:50:00.000Z
    SaleStarted: '', // Add UTC time, example: 2024-02-23T21:50:00.000Z
    SaleEnded: '', // Add UTC time, example: 2024-02-23T21:50:00.000Z
    SalePhase: 'whitelist',
    projectId: Number(localStorage.getItem('projectId')),
    AllowlistLink: '',
    MinTokens: 0
  })

  console.log(json)

  const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setJson({ ...json, [name]: value })
    console.log(json)
  }

  const handleChangeSelect = (event) => {
    setSaleType(event.target.value)
    const name = event.target.name
    const value = event.target.value
    setJson({ ...json, [name]: value })
  }

  const handleChangeAllowList = (event) => {
    setSalePhase(event.target.value)
    const name = event.target.name
    const value = event.target.value
    setJson({ ...json, [name]: value })
  }

  const handleSubmit = async () => {
    setLoad(true)
    // const name = {
    //   ...json, isAllowListed: json.SalePhase === "whitelist" ? true : false
    // }
    // console.log(name)
    
    await createSale({
      ...json, isAllowListed: json.SalePhase === "whitelist" ? true : false
    })
      .then((res) => {
        console.log('first')
        console.log(res.data)
        successHandler('New sale created!')
        setRerender('moving to whitelist')
        // navigate('/')
        localStorage.setItem("salePhase", json.SalePhase)
        setLoad(false)
      })
      .catch((e) => {
        errorHandler('Error while creating sale')
        // navigate('/salelive')
        setLoad(false)
      })
  }

  return (
    <div >
      {/* <Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
        Sale Management
      </Typography> */}
      <Grid container rowSpacing={2} >
      {salePhase === "whitelist" ? <> 
      <Grid item xs={12} sx={{ paddingRight: 3.5, mt:'3%' }}>
            <FormControl fullWidth >
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color: '#566077',
                  '&.Mui-focused': {
                    color: '#566077', // Focused color
                  },
                  '&.Mui-focused + .MuiInput-formControl': {
                    borderColor: '#566077', // Focused border color
                  },
                }}
              >
                Sale Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={json.Type}
                name="Type"
                label="Sale type"
                onChange={handleChangeSelect}
                className={classes.select}
              >
                <MenuItem value={'public'}>Public</MenuItem>
                <MenuItem value={'private'}>Private</MenuItem>
              </Select>
            </FormControl>
          </Grid>
      {json.Type === 'public' && <Grid item xs={12} sx={{ paddingRight: 3.5 }}>
          <TextField
            id=""
            label="Allow list link"
            name="AllowlistLink"
            value={json.AllowlistLink}
            onChange={handleChange}
            fullWidth
            className={classes.textField}
          />
        </Grid>}

          </> : ''}
        {json.Type === 'public' && <Grid item xs={12}>
          <FormControl>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className={classes.formLabel}
            >
              Allow List?
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              defaultValue="whitelist"
              name="SalePhase"
              onChange={handleChangeAllowList}
            >
              <FormControlLabel value="whitelist" defaultChecked control={<Radio />} label="Yes" />
              <FormControlLabel value="public" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>}

        
        {
          json.Type === 'private' ? <>
          <Grid item xs={12} sx={{ paddingRight: 3.5 }}>
          <TextField
            id=""
            label="Minimum start tokens required"
            nputLabelProps={{
              style: { color: '#fff' },
            }}
            name="MinTokens"
            value={json.MinTokens}
            onChange={handleChange}
            fullWidth
            className={classes.textField}
          />
        </Grid>
          </> : json.SalePhase === 'whitelist' && <>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    className={classes.datePicker}
                    label="List start"
                    onChange={(e) => {
                      const name = 'AllowlistSubmissionStarted'
                      const value = moment(e.$d).utc().format()
                      setJson({ ...json, [name]: value })
                      console.log(json)
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>{' '}
            </Grid>

            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    className={classes.datePicker}
                    label="List end"
                    onChange={(e) => {
                      const name = 'AllowlistSubmissionEnded'
                      const value = moment(e.$d).utc().format()
                      setJson({ ...json, [name]: value })
                      console.log(json)
                    }}
                  // name="AllowlistSubmissionEnded"
                  // value={json.AllowlistSubmissionEnded}
                  // onChange={handleChange}
                  />
                </DemoContainer>
              </LocalizationProvider>{' '}
            </Grid>
          </>
        }


        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                className={classes.datePicker}
                label="Sale start"
                onChange={(e) => {
                  const name = 'SaleStarted'
                  const value = moment(e.$d).utc().format()
                  setJson({ ...json, [name]: value })
                  console.log(json)
                }}
              // name="SaleStarted"
              // value={json.SaleStarted}
              // onChange={handleChange}
              />
            </DemoContainer>
          </LocalizationProvider>{' '}
        </Grid>

        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                className={classes.datePicker}
                label="Sale end"
                onChange={(e) => {
                  const name = 'SaleEnded'
                  const value = moment(e.$d).utc().format()
                  setJson({ ...json, [name]: value })
                  console.log(json)
                }}
              // name="SaleEnded"
              // value={json.SaleEnded}
              // onChange={handleChange}
              />
            </DemoContainer>
          </LocalizationProvider>{' '}
        </Grid>

        <Grid item xs={12} sx={{ paddingRight: 3.5 }}>
          <TextField
            id=""
            label="Number of tokens for sale"
            name="SaleSupply"
            value={json.SaleSupply}
            onChange={handleChange}
            fullWidth
            className={classes.textField}
          />
        </Grid>

        <Grid item xs={12} sx={{ paddingRight: 3.5 }}>
          <TextField
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       <AttachMoneyIcon sx={{ color: '#566077' }} />
            //     </InputAdornment>
            //   ),
            // }}
            id=""
            label="Price per token"
            type="number"
            name="SalePrice"
            value={json.SalePrice}
            onChange={handleChange}
            fullWidth
            className={classes.textField}
          />
        </Grid>

        <Grid item xs={12} sx={{ paddingRight: 3.5 }}>
          <TextField
            id=""
            label="User max allocation"
            nputLabelProps={{
              style: { color: '#fff' },
            }}
            name="UserMaxAllocation"
            value={json.UserMaxAllocation}
            onChange={handleChange}
            fullWidth
            className={classes.textField}
          />
        </Grid>

        {!load ? <Grid item xs={11.5}>
          <Button
            fullWidth
            variant="text"
            className="button"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid> :
          <Grid item xs={11.5} style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ backgroundImage: 'linear-gradient(90.35deg, #f59a6d -10.25%, #d64e5e 59.57%)', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '55%', padding: '1%' }}>
              <CircularProgress size={30} sx={{ color: 'white' }} />
            </div>
          </Grid>}
      </Grid>
    </div>
  )
}

export default SaleManagement
