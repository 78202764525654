import { React, useEffect, useState } from 'react'
import { Grid, Typography, Paper, Button, Box } from '@mui/material'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SummarizeIcon from '@mui/icons-material/Summarize'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LockIcon from '@mui/icons-material/Lock';
import '../../utils/custom.css'
import {
  getProjects,
  verifyProject,
  lockProject,
} from '../../services/saleServices'
import { successHandler, errorHandler } from '../../utils/toasts'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const ProjectsKanban = () => {
  const navigate = useNavigate()
  const [projects, setProjects] = useState()
  const [load, setLoad] = useState(false)
  const [flag, setFlag] = useState(false)

  var utcDate = new Date().toISOString()
  console.log(utcDate)

  useEffect(() => {
    async function fetchData() {
      setLoad(true)
      try {
        let res = await getProjects()
        setProjects(res.data.data)
        console.log(res.data.data)
        setLoad(false)
      } catch (e) {
        console.log(e)
        setLoad(false)
        errorHandler('Error while fetching projects')
      }
    }
    fetchData()
  }, [flag])

  const handleVerify = async (id) => {
    try {
      let res = await verifyProject({
        projectId: id,
      })
      console.log(res)
      successHandler('Project verified!')
      setFlag(!flag)
    } catch (error) {
      errorHandler('Error while verifying project')
      console.log(error)
    }
  }

  const handleLock = async (id) => {
    try {
      let res = await lockProject({
        projectId: id,
      })
      console.log(res)
      successHandler('Sale status changed!')
      setFlag(!flag)
    } catch (error) {
      errorHandler('Error while locking/unlocking project')
      console.log(error)
    }
  }

  const shortNum = (number) => {
    const suffixes = ["", "K", "L", "M", "B", "T"];
    const exponent = Math.floor(Math.log(Math.abs(number)) / Math.log(1000));
    if (exponent < 0) {
      return number.toFixed(2);
    }
    const rounded = (number / Math.pow(1000, exponent)).toFixed(1);
    return rounded + suffixes[exponent];
  }

  const handleClick = (id, salePhase, userWalletAddress, verified) => {
    try {
      localStorage.setItem('projectId', id)
      localStorage.setItem('salePhase', salePhase)
      localStorage.setItem('userWalletAddress', userWalletAddress)
      localStorage.setItem('verified', verified)
      navigate('/admin', {state:{id}})

    } catch (error) {
      errorHandler('Error while showing project details')
      console.log(error)
    }
  }

  return (
    <div style={{ minHeight: '100vh', width: '100%' }}>
      <Grid container spacing={2} sx={{ paddingRight: 5, paddingLeft: 5 }}>
        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <PeopleAltIcon sx={{ fontSize: '20px', marginRight: 1 }} />
          <h4>Live</h4>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <SummarizeIcon sx={{ fontSize: '20px', marginRight: 1 }} />
          <h4>Whitelist</h4>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircleIcon sx={{ fontSize: '20px', marginRight: 1 }} />
          <h4>Submission</h4>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <LockIcon sx={{ fontSize: '20px', marginRight: 1 }} />
          <h4>Locked</h4>
        </Grid>

        {load ? (
          //  <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Loading...</Typography>
          </Grid>
          //  </Grid>
        ) : (
          <>
            <Grid item xs={3}>
              {projects?.map((project) =>
                project?.SalePhase === 'public' ? (
                  <>
                    <Paper
                      sx={{
                        background: '#82698740',
                        color: '#fff',
                        borderRadius: '10px',
                        border: '1px solid #D64F5F57',
                        mb: 2,
                      }}
                    >
                      <Grid container spacing={2} sx={{ padding: '0.5em' }}>
                        <Grid item xs={3}>
                          <img
                            src={project.ProjectLogo}
                            style={{ width: '70px', height: '70px', objectFit: 'cover', borderRadius: '5px', border: '1px solid #D64F5F57' }}
                            alt=""
                          />
                          {/* </Box> */}
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <Grid item xs={4}>
                              <h4 style={{ margin: '0', padding: '0' }}>
                              {project.ProjectName.length > 5 ? project.ProjectName.slice(0,5) + '...' : project.ProjectName}
                              </h4>
                              <p style={{ fontSize: '10px', margin: '0', marginTop: '12%', padding: '0', color: '#969696' }}>
                                {project.ProjectSlogan.length <= 14 ? project.ProjectSlogan : project.ProjectSlogan.substring(0, 10) + '...'}
                              </p>
                            </Grid>
                            <Grid item xs={8} >
                              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {project.SalePhase === 'paused' ? (
                                  <Button
                                    size='small'
                                    sx={{ minWidth: '40px', marginRight: '5px', height:'20px', borderRadius: '5px' }}
                                    className="button"
                                    onClick={(id) => {
                                      handleLock(project.ProjectID)
                                    }}
                                  >
                                    Unlock
                                  </Button>
                                ) : (<>
                                  <Button
                                    size='small'
                                    sx={{ minWidth: '40px', marginRight: '5px', height:'20px', borderRadius: '5px' }}
                                    className="button"
                                    onClick={(id) => {
                                      handleLock(project.ProjectID)
                                    }}
                                  >
                                    Lock
                                  </Button>
                                  <Button
                                    size='small'
                                    sx={{ minWidth: '40px', marginRight: '5px', height:'20px', px: '0', borderRadius: '5px' }}
                                    className="button"
                                    onClick={() => handleClick(project?.ProjectID, project?.SalePhase, project?.userWalletAddress, project?.verified)}

                                  >
                                    View
                                  </Button></>
                                )}
                              </div>

                              <p style={{ fontSize: '10px', margin: '0', marginTop: '5%', textAlign: 'end', padding: '0', color: '#969696' }}>
                                {utcDate > project.SaleStarted &&
                                  utcDate < project.SaleEnded
                                  ? "Live"
                                  : <>Sale starts on <strong>{moment().format('MMMM Do YYYY')}</strong></>}
                              </p>

                            </Grid>
                          </Grid>
                          <Grid container sx={{ mt: '4%' }}>
                            <Grid item xs={6} sx={{
                              background: 'linear-gradient(90.35deg, rgba(245, 154, 109, 0.25) -10.25%, rgba(214, 78, 94, 0.25) 59.57%)', p: '4px', borderRadius: '5px 0px 0px 5px',
                              borderImageSource: 'linear-gradient(90.35deg, #F59A6D -10.25%, #D64E5E 59.57%)', border: '1px'
                            }}>
                              <p style={{ fontSize: '10px', margin: '0', padding: '0', color: '#969696' }}>
                                Fundraise Goal: $
                                {shortNum(Number(project?.FundraiseGoal).toFixed(2))}
                              </p>
                            </Grid>
                            <Grid item xs={6} sx={{
                              background: 'linear-gradient(90.01deg, rgba(238, 44, 27, 0.05) 0.01%, rgba(243, 110, 35, 0.05) 99.99%)', p: '4px', borderRadius: '0px 5px 5px 0px',
                              borderImageSource: 'linear-gradient(90.35deg, #F59A6D -10.25%, #D64E5E 59.57%)', border: '1px'
                            }}>
                              <p style={{ fontSize: '10px', textAlign: 'end', margin: '0', padding: '0', color: '#969696' }}>
                                Max Allocation: $
                                {shortNum(Number(project?.MaxAllocation).toFixed(2))}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>
                    </Paper>
                  </>
                ) : (
                  ''
                )
              )}
            </Grid>

            <Grid item xs={3}>
              {projects?.map((project) =>
                project?.SalePhase === 'whitelist' ? (
                  <>
                    <Paper
                      sx={{
                        background: '#82698740',
                        color: '#fff',
                        // width: '45vw',
                        // border: '1px solid #566077',
                        borderRadius: '10px',
                        border: '1px solid #D64F5F57',
                        mb: 2,
                        // p: 1
                      }}
                    >
                      <Grid container spacing={2} sx={{ padding: '0.5em' }}>
                        <Grid item xs={3}>
                          <img
                            src={project.ProjectLogo}
                            style={{ width: '70px', height: '70px', objectFit: 'cover', borderRadius: '5px', border: '1px solid #D64F5F57' }}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <Grid item xs={4}>
                              <h4 style={{ margin: '0', padding: '0' }}>
                              {project.ProjectName.length > 5 ? project.ProjectName.slice(0,5) + '...' : project.ProjectName}
                              </h4>
                              <p style={{ fontSize: '10px', margin: '0', marginTop: '12%', padding: '0', color: '#969696' }}>
                                {project.ProjectSlogan.length <= 14 ? project.ProjectSlogan : project.ProjectSlogan.substring(0, 10) + '...'}
                              </p>
                            </Grid>
                            <Grid item xs={8} >
                              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {project.SalePhase === 'paused' ? (
                                  <Button
                                    size='small'
                                    sx={{ minWidth: '40px', marginRight: '5px', height:'20px', borderRadius: '5px' }}
                                    className="button"
                                    onClick={(id) => {
                                      handleLock(project.ProjectID)
                                    }}
                                  >
                                    Unlock
                                  </Button>
                                ) : (<>
                                  <Button
                                    size='small'
                                    sx={{ minWidth: '40px', marginRight: '5px', height:'20px', borderRadius: '5px' }}
                                    className="button"
                                    onClick={(id) => {
                                      handleLock(project.ProjectID)
                                    }}
                                  >
                                    Lock
                                  </Button>
                                  <Button
                                    size='small'
                                    sx={{ minWidth: '40px', marginRight: '5px', height:'20px', px: '0', borderRadius: '5px' }}
                                    className="button"
                                    onClick={() => handleClick(project?.ProjectID, project?.SalePhase, project?.userWalletAddress, project?.verified)}

                                  >
                                    View
                                  </Button></>
                                )}
                              </div>

                              <p style={{ fontSize: '10px', margin: '0', marginTop: '5%', textAlign: 'end', padding: '0', color: '#969696' }}>
                                {utcDate > project.SaleStarted &&
                                  utcDate < project.SaleEnded
                                  ? "Live"
                                  : <>Sale starts on <strong>{moment().format('MMMM Do YYYY')}</strong></>}
                              </p>

                            </Grid>
                          </Grid>
                          <Grid container sx={{ mt: '4%' }}>
                            <Grid item xs={6} sx={{
                              background: 'linear-gradient(90.35deg, rgba(245, 154, 109, 0.25) -10.25%, rgba(214, 78, 94, 0.25) 59.57%)', p: '4px', borderRadius: '5px 0px 0px 5px',
                              borderImageSource: 'linear-gradient(90.35deg, #F59A6D -10.25%, #D64E5E 59.57%)', border: '1px'
                            }}>
                              <p style={{ fontSize: '10px', margin: '0', padding: '0', color: '#969696' }}>
                                Fundraise Goal: $
                                {shortNum(Number(project?.FundraiseGoal).toFixed(2))}
                              </p>
                            </Grid>
                            <Grid item xs={6} sx={{
                              background: 'linear-gradient(90.01deg, rgba(238, 44, 27, 0.05) 0.01%, rgba(243, 110, 35, 0.05) 99.99%)', p: '4px', borderRadius: '0px 5px 5px 0px',
                              borderImageSource: 'linear-gradient(90.35deg, #F59A6D -10.25%, #D64E5E 59.57%)', border: '1px'
                            }}>
                              <p style={{ fontSize: '10px', textAlign: 'end', margin: '0', padding: '0', color: '#969696' }}>
                                Max Allocation: $
                                {shortNum(Number(project?.MaxAllocation).toFixed(2))}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>
                    </Paper>
                  </>
                ) : (
                  ''
                )
              )}
            </Grid>

            <Grid item xs={3}>
              {projects?.map((project) =>
                // project?.verified === 'false' ? (
                <>
                  <Paper
                    sx={{
                      background: '#82698740',
                      color: '#fff',
                      // width: '45vw',
                      // border: '1px solid #566077',
                      borderRadius: '10px',
                      border: '1px solid #D64F5F57',
                      mb: 2
                    }}
                  >
                    <Grid container spacing={2} sx={{ padding: '0.5em' }}>
                      <Grid item xs={3}>
                        <img
                          src={project.ProjectLogo}
                          style={{ width: '70px', height: '70px', objectFit: 'cover', borderRadius: '5px', border: '1px solid #D64F5F57' }}
                          alt=""
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Grid container sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                          <Grid item xs={4}>
                            <h4 style={{ margin: '0', padding: '0' }}>
                            {project.ProjectName.length > 5 ? project.ProjectName.slice(0,5) + '...' : project.ProjectName}
                            </h4>
                            <p style={{ fontSize: '10px', margin: '0', marginTop: '12%', padding: '0', color: '#969696' }}>
                              {project.ProjectSlogan.length <= 14 ? project.ProjectSlogan : project.ProjectSlogan.substring(0, 10) + '...'}
                            </p>
                          </Grid>
                          <Grid item xs={8} >
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              {project?.verified === 'false' ? <>
                                <Button
                                  className="button"
                                  onClick={() => {
                                    handleVerify(project.ProjectID)
                                  }}
                                  size='small'
                                  sx={{ minWidth: '50px', height:'20px', marginRight: '5px', px: '0', borderRadius: '5px' }}
                                >
                                  Verify
                                </Button>
                                <Button
                                  className="button"
                                  onClick={() => handleClick(project?.ProjectID, project?.SalePhase, project?.userWalletAddress, project?.verified)}
                                  size='small'
                                  sx={{ minWidth: '40px', height:'20px', marginRight: '5px', px: '0', borderRadius: '5px' }}
                                >
                                  View
                                </Button>
                              </> : <Button
                                size='small'
                                sx={{ minWidth: '40px', height:'20px', marginRight: '5px', px: '0', borderRadius: '5px' }}
                                className="button"
                                onClick={() => handleClick(project?.ProjectID, project?.SalePhase, project?.userWalletAddress, project?.verified)}
                              >
                                View
                              </Button>}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container sx={{ mt: '4%' }}>
                          <Grid item xs={6} sx={{
                            background: 'linear-gradient(90.35deg, rgba(245, 154, 109, 0.25) -10.25%, rgba(214, 78, 94, 0.25) 59.57%)', p: '4px', borderRadius: '5px 0px 0px 5px',
                            borderImageSource: 'linear-gradient(90.35deg, #F59A6D -10.25%, #D64E5E 59.57%)', border: '1px'
                          }}>
                            <p style={{ fontSize: '10px', margin: '0', padding: '0', color: '#969696' }}>
                              Fundraise Goal: $
                              {shortNum(Number(project?.FundraiseGoal))}
                            </p>
                          </Grid>
                          <Grid item xs={6} sx={{
                            background: 'linear-gradient(90.01deg, rgba(238, 44, 27, 0.05) 0.01%, rgba(243, 110, 35, 0.05) 99.99%)', p: '4px', borderRadius: '0px 5px 5px 0px',
                            borderImageSource: 'linear-gradient(90.35deg, #F59A6D -10.25%, #D64E5E 59.57%)', border: '1px'
                          }}>
                            <p style={{ fontSize: '10px', textAlign: 'end', margin: '0', padding: '0', color: '#969696' }}>
                              Max Allocation: $
                              {shortNum(Number(project?.MaxAllocation))}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>


                    </Grid>
                  </Paper>
                </>
                // ) : (
                //   ''
                // )
              )}
            </Grid>

            <Grid item xs={3}>
              {projects?.map((project) =>
                project?.SalePhase === 'paused' ? (
                  <>
                    <Paper
                      sx={{
                        background: '#82698740',
                        color: '#fff',
                        // width: '45vw',
                        // border: '1px solid #566077',
                        borderRadius: '10px',
                        border: '1px solid #D64F5F57',
                        mb: 2,
                      }}
                    >
                      <Grid container spacing={2} sx={{ padding: '0.5em' }}>
                        <Grid item xs={3}>
                          <img
                            src={project.ProjectLogo}
                            style={{ width: '70px', height: '70px', objectFit: 'cover', borderRadius: '5px', border: '1px solid #D64F5F57' }}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <Grid item xs={4}>
                              <h4 style={{ margin: '0', padding: '0' }}>
                                {project.ProjectName.length > 5 ? project.ProjectName.slice(0,5) + '...' : project.ProjectName}
                              </h4>
                              <p style={{ fontSize: '10px', margin: '0', marginTop: '12%', padding: '0', color: '#969696' }}>
                                {project.ProjectSlogan.length <= 14 ? project.ProjectSlogan : project.ProjectSlogan.substring(0, 10) + '...'}
                              </p>
                            </Grid>
                            <Grid item xs={8} >
                              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                {project.SalePhase === 'paused' ? (<>
                                  <Button
                                    className="button"
                                    onClick={(id) => {
                                      handleLock(project.ProjectID)
                                    }}
                                    size='small'
                                    sx={{ minWidth: '50px', height:'20px', marginRight: '5px', px: '0', borderRadius: '5px' }}
                                  >
                                    Unlock
                                  </Button>
                                  <Button
                                    className="button"
                                    onClick={() => handleClick(project?.ProjectID, project?.SalePhase, project?.userWalletAddress, project?.verified)}
                                    size='small'
                                    sx={{ minWidth: '40px', height:'20px', marginRight: '5px', px: '0', borderRadius: '5px' }}
                                  >
                                    View
                                  </Button></>
                                ) : (
                                  <Button
                                    className="button"
                                    onClick={(id) => {
                                      handleLock(project.ProjectID)
                                    }}
                                    size='small'
                                    sx={{ minWidth: '40px', height:'20px', marginRight: '5px', px: '0', borderRadius: '5px' }}
                                  >
                                    Lock
                                  </Button>
                                )}
                              </div>
                              <p style={{ fontSize: '10px', margin: '0', marginTop: '5%', textAlign: 'end', padding: '0', color: '#969696' }}>
                                {utcDate > project.SaleStarted &&
                                  utcDate < project.SaleEnded
                                  ? "Live"
                                  : <>Sale starts on <strong>{moment().format('MMMM Do YYYY')}</strong></>}
                              </p>
                            </Grid>
                          </Grid>
                          <Grid container sx={{ mt: '4%' }}>
                          <Grid item xs={6} sx={{
                            background: 'linear-gradient(90.35deg, rgba(245, 154, 109, 0.25) -10.25%, rgba(214, 78, 94, 0.25) 59.57%)', p: '4px', borderRadius: '5px 0px 0px 5px',
                            borderImageSource: 'linear-gradient(90.35deg, #F59A6D -10.25%, #D64E5E 59.57%)', border: '1px'
                          }}>
                            <p style={{ fontSize: '10px', margin: '0', padding: '0', color: '#969696' }}>
                              Fundraise Goal: $
                              {shortNum(Number(project?.FundraiseGoal))}
                            </p>
                          </Grid>
                          <Grid item xs={6} sx={{
                            background: 'linear-gradient(90.01deg, rgba(238, 44, 27, 0.05) 0.01%, rgba(243, 110, 35, 0.05) 99.99%)', p: '4px', borderRadius: '0px 5px 5px 0px',
                            borderImageSource: 'linear-gradient(90.35deg, #F59A6D -10.25%, #D64E5E 59.57%)', border: '1px'
                          }}>
                            <p style={{ fontSize: '10px', textAlign: 'end', margin: '0', padding: '0', color: '#969696' }}>
                              Max Allocation: $
                              {shortNum(Number(project?.MaxAllocation))}
                            </p>
                          </Grid>
                        </Grid>
                        </Grid>
                        
                      </Grid>
                    </Paper>
                  </>
                ) : (
                  ''
                )
              )}
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

export default ProjectsKanban
