import { useEffect, useState } from 'react'
import { Table, Chip } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import DeleteIcon from '@mui/icons-material/Delete'
import deleteIcon from '../../images/deleteIcon.png'
import useStyles from '../../utils/styles'
import { getAddresses } from '../../services/saleServices'
import { formatWalletAddress } from '../../utils/helpers'

export default function BasicTable() {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const url = window.location.href.split('/')[3]

  useEffect(() => {
    async function fetchData() {
      const response = await getAddresses({
        // saleId: 28,
        projectId: Number(localStorage.getItem('projectId')),
      })
      const data = response.data.data
      // console.log(data)
      // console.log(url.includes === 'airdropTokens')
      // console.log(url === 'airdropTokens')
      setRows(data)
    }
    fetchData()
    if(localStorage.getItem('salePhase') === 'public'){
      const interval = setInterval(() => {
        fetchData()
      }, 3 * 1000)
      return () => clearInterval(interval)
    }
  }, [])

  return (
    <TableContainer
      component={Paper}
      sx={{ backgroundColor: '#000000', color: '#ffffff' }}
    >
      <Table
        sx={{ minWidth: 400, borderCollapse: 'collapse' }}
        aria-label="simple table"
      >
        <TableBody>
          {url === 'airdropTokens'
            ? //show only funded rows
              rows.map((row) =>
                row.tokenReceive !== null ? (
                  <TableRow
                    key={row.name}
                    sx={{
                      backgroundColor: '#F59A6D12',
                      border: '1px solid #F36E231A',
                      borderBottom: '1px solid transparent',
                      '&:last-child td, &:last-child th': { border: 0 }, // Remove border from last row
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        color: '#fff',
                        borderTop: '1px solid #F36E231A',
                        borderBottom: 0,
                      }}
                    >
                      {formatWalletAddress(row.userWalletAddress, 15, 15)}
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        borderTop: '1px solid #F36E231A',
                        borderBottom: 0,
                      }}
                    >
                      <Chip
                        label="Funded"
                        sx={{
                          background: '#287B5330',
                          border: '1px solid #287B53',
                          fontFamily: 'Exo 2',
                          color: '#287B53',
                          '& .MuiChip-label': {
                            fontFamily: 'Exo 2 !important',
                          },
                        }}
                        // className={classes.successChip}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: '#fff',
                        borderTop: '1px solid #F36E231A',
                        borderBottom: 0,
                      }}
                    >
                      {row.tokenReceive}
                    </TableCell>
                  </TableRow>
                ) : null
              )
            : //show funded as well as remaining rows
              rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    backgroundColor: '#F59A6D12',
                    border: '1px solid #F36E231A',
                    borderBottom: '1px solid transparent',
                    '&:last-child td, &:last-child th': { border: 0 }, // Remove border from last row
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      color: '#fff',
                      borderTop: '1px solid #F36E231A',
                      borderBottom: 0,
                    }}
                  >
                    {formatWalletAddress(row.userWalletAddress, 15, 15)}
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: '#fff',
                      borderTop: '1px solid #F36E231A',
                      borderBottom: 0,
                    }}
                  >
                    {url.includes('airdropTokens') ? (
                      ''
                    ) : row.tokenReceive !== null ? (
                      <Chip
                        label="Funded"
                        sx={{
                          background: '#287B5330',
                          border: '1px solid #287B53',
                          fontFamily: 'Exo 2',
                          color: '#287B53',
                          '& .MuiChip-label': {
                            fontFamily: 'Exo 2 !important',
                          },
                        }}
                        // className={classes.successChip}
                      />
                    ) : (
                      <Chip
                        label="Remaining"
                        sx={{
                          background: '#992A2A30',
                          border: '1px solid #992A2A',
                          fontFamily: 'Exo 2',
                          color: '#992A2A',
                        }}
                        // className={classes.remainingChip}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: '#fff',
                      borderTop: '1px solid #F36E231A',
                      borderBottom: 0,
                    }}
                  >
                    {row.tokenReceive !== null ? row.tokenReceive : '-'}
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
