import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { useContext, useEffect } from 'react'
// import { farmer } from '../context/MainContext';
// import SignupPage from '../pages/SignupPage';
import HomePage from '../pages/Home'
import AdminLoginPage from '../pages/AdminLogin'
import SaleManagementPage from '../pages/SaleManagement'
import WhitelistPage from '../pages/Whitelist'
import SaleLivePage from '../pages/SaleLive'
import AirdropTokensPage from '../pages/AirdropTokens'
import SaleEndedPage from '../pages/SaleEnded'
import AdminDashboard from '../pages/AdminDashboard'
import AdminHome from '../pages/AdminHome'
import ProjNotVerified from '../pages/ProjNotVerified'

export default function MainRouter() {
  // change func
  function PrivateRouter() {
    const user = localStorage.getItem("adminToken")
      return user !== null ? <>
          <Outlet />
      </> : <>
          {
              (localStorage.getItem("adminToken")) === null && <Navigate to="/adminLogin" />
          }

      </>
  }

  // useEffect(() => {
  //     setUser(JSON.parse(localStorage.getItem("codivasUser")))
  // }, [])

  return (
    <>
      <Routes>
        {/* change components according to your naming config */}
        <Route exact path="/" element={<HomePage />} />
        {/* <Route exact path="/salemanagement" element={<SaleManagementPage />} />
        <Route exact path="/whitelist" element={<WhitelistPage />} />
        <Route exact path="/salelive" element={<SaleLivePage />} />
        <Route exact path="/airdroptokens" element={<AirdropTokensPage />} />
        <Route exact path="/saleended" element={<SaleEndedPage />} /> */}

        <Route exact path="/adminlogin" element={<AdminLoginPage />} />
        <Route exact path="/notverified" element={<ProjNotVerified />} />

        {/* private pages router */}

        <Route path='/admindash' element={<PrivateRouter />} >
            <Route exact path="/admindash" element={<AdminDashboard />} />
        </Route>
        <Route path='/admin' element={<PrivateRouter />} >
            <Route exact path="/admin" element={<AdminHome />} />
        </Route>
      </Routes>
    </>
  )
}
