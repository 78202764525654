import { React, useState } from 'react'
import HeadNav from '../components/navbar/HeadNav'
import { Grid, TextField, Typography, Button, Paper, CircularProgress } from '@mui/material'
import ProjectDetails from '../components/ProjectDetails'
import FootNav from '../components/navbar/FootNav'
import useStyles from '../utils/styles'
import { useNavigate } from 'react-router-dom'
import { adminLogin } from '../services/saleServices'
import { successHandler, errorHandler } from '../utils/toasts'
import httpcommon from '../httpcommon'
import { useWallet } from '../context/WalletProvider'

const AdminLoginPage = () => {
  const classes = useStyles()
  const {setWalletAddress} = useWallet()
  const [load, setLoad] = useState(false)
  const navigate = useNavigate()
  const [json, setJson] = useState({
    email: '',
    password: '',
  })

  const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setJson({ ...json, [name]: value })
    console.log(json)
  }

  const handleLogin = async () => {
    setLoad(true)

    await adminLogin({
      ...json,
    })
      .then((res) => {
        console.log(res.data)
        localStorage.clear()
        setWalletAddress(null)
        successHandler('Logged In!')
        navigate('/admindash')
        localStorage.setItem('adminToken', res.data.adminToken)
        setLoad(false)
        delete httpcommon.defaults.headers.common['ManagerToken'];
        httpcommon.defaults.headers.common['AdminToken'] = localStorage.getItem('adminToken')
      })
      .catch((e) => {
        errorHandler('Error while logging in')
        // navigate('/salelive')
        setLoad(false)
      })
  }

  return (
    <>
      <HeadNav>
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '100vh' }}
        >
          <Paper
            sx={{
              background: '#0B0D15',
              color: '#fff',
              padding: 5,
              border: '1px solid #566077',
              borderRadius: '10px',
              width: '50%',
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Enter details to login
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id=""
                label="Email"
                name="email"
                value={json.email}
                onChange={handleChange}
                fullWidth
                className={classes.textField}
                sx={{ mb: 1 }}
              // sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id=""
                label="Password"
                name="password"
                value={json.password}
                onChange={handleChange}
                fullWidth
                className={classes.textField}
                sx={{ mb: 2 }}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className="button"
                sx={{
                  fontFamily: 'exo 2',
                  // marginLeft: 3,
                  // height: '50%',
                  width: '100%',
                  borderRadius: '10px',
                  '&.Mui-disabled':{background:'transparent', color:'#ffffff4c !important', border:'1px solid #ffffff4c'}
                }}
                disabled={load}
                onClick={handleLogin}
              >
              {load ? <CircularProgress size={15} sx={{color:'#ffffff4c', mr:2}} /> : null}
                Login
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </HeadNav>
      <FootNav />
    </>
  )
}

export default AdminLoginPage
