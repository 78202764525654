import { getFromLocalStorage, setToLocalStorage } from '../utils/storage'
import React, { createContext, useContext, useState, useEffect } from 'react'

const WalletContext = createContext(null)

export const useWalletContext = () => useContext(WalletContext)

export const WalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(
    localStorage.getItem('walletAddress')
  )
  const [status, setStatus] = useState(getFromLocalStorage('status'))

  const setValue = (key, value) => {
    setToLocalStorage(key, value)
    setToLocalStorage('status', 'uniset')
    setWalletAddress(value)
    setStatus('uniset')
  }

  const value = React.useMemo(
    () => ({
      walletAddress,
      setWalletAddress,
      status,
      setStatus,
      setValue,
    }),
    [walletAddress, setWalletAddress, status, setStatus, setValue]
  )

  return React.createElement(
    WalletContext.Provider,
    { value: { ...value } },
    children
  )
}

export const useWallet = () => {
  return useContext(WalletContext)
}
