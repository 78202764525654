import { React, useState } from 'react'
import { TextField, Grid, Button, CircularProgress } from '@mui/material'
import useStyles from '../../utils/styles'
import '../../utils/custom.css'
import { errorHandler } from '../../utils/toasts'
import { isMainnetUrl } from '../../utils/helpers'


const UploadCSV = ({flag, setFlag, load, setLoad}) => {
  const classes = useStyles()
  const [file, setFile] = useState(null)
  const [column, setColumn] = useState('')

  const handleUpload = (e) => {
    setFile(e.target.files[0])
    console.log(e.target.files[0])
  }

  console.log(file, 'llll')

  const handleSubmit = () => {
    setLoad(true)
    if(column === ''){
      errorHandler("Please enter column name")
      setLoad(false)
      return
    }
    const formdata = new FormData();

    console.log("File before append:", file); // Log file before appending

    formdata.append("userAddressCSV", file, file.name);
    formdata.append("columnName", column);
    formdata.append("projectId", Number(localStorage.getItem('projectId')));

    console.log("formData after append:", formdata); // Log formData after appending
    // Log individual entries for further inspection if needed:
    // console.log("userAddressCSV:", formdata.get("userAddressCSV"));
    // console.log("columnName:", formdata.get("columnName"));
    // console.log("projectId:", formdata.get("projectId"));
    for (const entry of formdata.entries()) {
      console.log(entry);
    }
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      headers: new Headers({
        "ManagerToken" : localStorage.getItem("managerToken")
      })
    };

 
    
    const isMainnet = isMainnetUrl()
    const url = isMainnet ? `https://app.tapstarter.io/api/v1` : `https://testnet.tapstarter.io/api/v1`

    fetch(`${url}/blockchain/addWhiteListCSV`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((result) => {
        console.log(result);
        setFlag(!flag);
        setLoad(false)
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoad(false)

        // Handle errors appropriately (e.g., display error message to user)
      });
  };

  return (
    <div style={{padding:'0', margin:'0'}} >
      <Grid container RowSpacing={2}>
      <Grid item xs={12}>
        <TextField fullWidth className={classes.textField} size='small' sx={{marginBottom:'5%'}} value={column} onChange={(e) => setColumn(e.target.value)} placeholder='Column name containing addresses' />
      </Grid>
        <Grid item xs={9}>
          {/* {file === '' ? (
            <label for="file-upload"  class="custom-file-upload">
              Upload File
            </label>
          ) : (
            <label for="file-upload" class="custom-file-upload">
              {file?.name}
            </label>
          )}

          <TextField placeholder='Upload file' id="file-upload" className={classes.textField} type="file" onChange={handleUpload} /> */}


          <Button
            variant="contained"
            component="label"
            sx={{ backgroundColor: 'transparent', '&:hover': { bgcolor: 'transparent' }, display: 'flex', justifyContent: 'flex-start', fontWeight: '300', color: '#566077', width: '100%', borderRadius: '10px', border: '1px solid #566077' }}
          >
            {file === '' || file === null ? 'Upload File' : file?.name}
            <input
              type="file"
              accept=".csv"
              onChange={handleUpload}
              hidden
            />
          </Button>
          {/* <input type="file" onChange={handleUpload} style={{color: 'white'}}/>  */}

          {/* <TextField
                id="file"
                // value={}
                onChange={handleUpload}
                type="file"
                sx={{color: '#fff'}}
              /> */}
        </Grid>
        <Grid item xs={3}>
          {!load ? <Button
            className="button"
            sx={{
              fontFamily: 'Exo 2',
              marginLeft: 3,
              width: '80%',
              borderRadius: '10px',
            }}
            onClick={handleSubmit}
          >
            Upload
          </Button> :
            <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 3, width: '100%' }}>
              <div style={{ backgroundImage: 'linear-gradient(90.35deg, #f59a6d -10.25%, #d64e5e 59.57%)', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '55%', padding: '4%' }}>
                <CircularProgress size={30} sx={{ color: 'white' }} />
              </div>
            </div>}
        </Grid>
        {/* {flag ? <Grid item xs={12}>
          <AddressTable  />
        </Grid>: '' } */}
        {/* <Grid item xs={12}>
          <AddressTable flag={flag} setFlag={setFlag} setLoad={setLoad} />
        </Grid> */}
        {/* <Grid item xs={12}>
          <AddressTable  />
        </Grid> */}
      </Grid>
    </div>
  )
}

export default UploadCSV
