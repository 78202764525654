const setToLocalStorage = (key: string, value: null | string) => {
  if (value) {
    localStorage.setItem(key, JSON.stringify(value))
  } else localStorage.removeItem(key)
}

const getFromLocalStorage = (key: string) => {
  let response = ''
  try {
    const value = localStorage.getItem(key)
    response = value ? JSON.parse(value) : ''
  } catch (error) {
    response = ''
  }

  return response
}

const deleteFromLocalStorage = (key: string) => {
  localStorage.removeItem(key)
}

const logout = () => {
  setToLocalStorage('user', null)
}

const isLoggedIn = () => {
  return !!getFromLocalStorage('user')
}

export {
  setToLocalStorage,
  getFromLocalStorage,
  deleteFromLocalStorage,
  logout,
  isLoggedIn,
}
