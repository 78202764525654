import React, { useRef, useState, useEffect } from 'react'
import { Grid, Stack, Button, Typography, Box, Tooltip, CardMedia, Table, TableContainer, TableBody, TableRow, TableCell, Paper } from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import '../utils/custom.css'
import { getProjectByUser } from '../services/saleServices'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useWallet } from '../context/WalletProvider'

const styles = {
  grid: {
    paddingTop: '1em',
    paddingLeft: '3rem',
  },
}
const ProjectDetails = () => {
  const [project, setProject] = useState()
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const { walletAddress } = useWallet()

  useEffect(() => {
    async function fetchData() {
      try {
        let res = await getProjectByUser({
          userWalletAddress: walletAddress,
        })
        setProject(res.data.data)
        console.log(res.data.data)
        localStorage.setItem('projectId', res.data.data.ProjectID)
        localStorage.setItem('verified', res.data.data.verified)
        localStorage.setItem('salePhase', res.data.data.SalePhase)

      } catch (e) {
        localStorage.setItem('salePhase', 'nosale')
        console.log(e)
      }
    }
    fetchData()
  }, [])

  const handleCopy = () => {
    const textToCopy = project?.projectWalletAddress
    if (textToCopy) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          console.log('Text copied to clipboard:', textToCopy)
          setTooltipOpen(true)
          setTimeout(() => {
            setTooltipOpen(false)
          }, 1500)
        })
        .catch((error) => {
          console.error('Failed to copy text: ', error)
          // Handle error, such as showing an error message to the user
        })
    }
  }

  return (
    <div style={{ fontFamily: 'Exo 2' }}>
      <Grid style={styles.grid} container spacing={2}>
        <Grid item xs={1.5}>
          <Box
            display="flex"
            alignItems="center"
          // height="50vh"
          >
            <img src={project?.ProjectLogo}
              style={{ width: '70px', height: '70px', objectFit: 'cover', borderRadius: '5px', border: '1px solid #D64F5F57' }}
              alt="logo" width="80%" />
          </Box>
        </Grid>
        <Grid item xs={10.5}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3 style={{ margin: '0', padding: '0' }}>{project?.ProjectName}</h3>
              <div style={{display:'flex', alignItems:'center'}}>
              {project?.SalePhase === null ? '' : <Button
              className="button"
              style={{
                maxWidth: 'auto',
                maxHeight: '3em',
                fontSize:'12px',
                fontFamily: 'Exo 2!important',
                borderRadius:'10px',
                border:'none'
              }}
            >
              Sale {project?.SalePhase}
            </Button>}
           &nbsp;
              <div style={{ background: '#0B0D15', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', borderRadius: '10px', color: '#566077' }}>
                <h5 style={{ margin: '0', padding: '0' }}>Project Wallet &nbsp;  | &nbsp; </h5>
                <p style={{ margin: '0', padding: '0' }}
                >
                  {project?.projectWalletAddress?.slice(0, 3) + '......' + project?.projectWalletAddress?.slice(-8)}
                </p>
                <Tooltip open={tooltipOpen} title="Copied!">
                  <ContentCopyIcon
                    sx={{
                      marginLeft: '0.5em',
                      fontSize: '15px',
                      color: '#566077',
                      cursor: 'pointer',
                    }}
                    onClick={handleCopy}
                    color="#566077"
                  />
                </Tooltip>
              </div>
              </div>
            </div>
            <p style={{ fontSize: '12px', margin: '0', padding: '0', color: '#969696' }}>{project?.ProjectSlogan}</p>

          </div>
        </Grid>

        <Grid item xs={12}>

          {console.log(project?.ProjectMedia)}
          <Carousel
            autoPlay={true}
            swipe={true}
            indicators={false}
            cycleNavigation={true}
            interval={5000}
            animation='fade'
          >
            {
              project?.ProjectMedia.map((item, i) => <Item key={i} item={item} i={i === 0 ? true : false} />)
            }
          </Carousel>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h6" className="fireGradient" sx={{ my: 2 }}>
            Project Summary
          </Typography>
          <Typography variant="p" sx={{ my: 2, fontSize: '14px' }}>
            {project?.ProjectSummary}
          </Typography>
          {/* <Typography variant="h6" className="fireGradient" sx={{ my: 2 }}>
            HEADING
          </Typography>
          <Typography variant="body1" sx={{ my: 2 }}>
            Bitcoin Frogs are 10,000 pure digital collectibles that will remain
            on Bitcoin forever. No more will ever be created. Rarities of all
            traits within each layer are equal, allowing subjective appreciation
            of aesthetics and Satoshi-based rarities to emerge.
          </Typography> */}
        </Grid>
        <Grid item xs={5} style={{ paddingLeft: '3em' }}>
          <Typography variant="h6" className='fireGradient' sx={{ mb: 5, my: 2 }}>
            Sale Details
          </Typography>
          <TableContainer
            component={Paper}
            sx={{ backgroundColor: '#000000', color: '#ffffff' }}
          >
            <Table
              sx={{ borderCollapse: 'collapse' }}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow
                  sx={{
                    backgroundColor: '#F59A6D12',
                    border: '1px solid #F36E231A',
                    borderBottom: '1px solid transparent',
                    '&:last-child td, &:last-child th': { border: 0 }, // Remove border from last row
                  }}
                >


                  <TableCell
                    align="left"
                    sx={{
                      color: '#fff',
                      borderTop: '1px solid #F36E231A',
                      borderBottom: 0,
                    }}
                  >
                    Fundraise Goal
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: '#fff',
                      borderTop: '1px solid #F36E231A',
                      borderBottom: 0,
                    }}
                  >
                    ${Number(project?.FundraiseGoal).toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    backgroundColor: '#F59A6D12',
                    border: '1px solid #F36E231A',
                    borderBottom: '1px solid transparent',
                    '&:last-child td, &:last-child th': { border: 0 }, // Remove border from last row
                  }}
                >


                  <TableCell
                    align="left"
                    sx={{
                      color: '#fff',
                      borderTop: '1px solid #F36E231A',
                      borderBottom: 0,
                    }}
                  >
                    Max Allocation
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: '#fff',
                      borderTop: '1px solid #F36E231A',
                      borderBottom: 0,
                    }}
                  >
                    ${Number(project?.MaxAllocation).toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    backgroundColor: '#F59A6D12',
                    border: '1px solid #F36E231A',
                    borderBottom: '1px solid transparent',
                    '&:last-child td, &:last-child th': { border: 0 }, // Remove border from last row
                  }}
                >


                  <TableCell
                    align="left"
                    sx={{
                      color: '#fff',
                      borderTop: '1px solid #F36E231A',
                      borderBottom: 0,
                    }}
                  >
                    Price per token
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: '#fff',
                      borderTop: '1px solid #F36E231A',
                      borderBottom: 0,
                    }}
                  >
                    $0.05
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  )
}

function Item(props) {
  return (
    <>
      <Box sx={{ margin: 0, padding: 0 }} >
        <CardMedia component='img' image={props.item.VideoImages} sx={{ height: '100vh' }} />
      </Box>
    </>
  )
}

export default ProjectDetails
