import { React, useState } from 'react'
import { TextField, Grid, Button, CircularProgress } from '@mui/material'
import AddressTable from './AddressTable'
import useStyles from '../../utils/styles'
import '../../utils/custom.css'
import { addAddress } from '../../services/saleServices'
import {errorHandler, successHandler} from '../../utils/toasts'
import { isValidTaprootAddress } from '../../utils/helpers'

const UploadManually = ({flag, setFlag, load, setLoad}) => {
  const classes = useStyles()
console.log(load)
 
  const [json, setJson] = useState({
    projectId: Number(localStorage.getItem('projectId')),
    addresses: [],
  })
  console.log(json)
  const addresses = []

  const handleChange = (event) => {
    console.log(isValidTaprootAddress(event.target.value)) 
    const name = event.target.name
    const value = event.target.value

    setJson({ ...json, [name]: [value] })
    console.log(json)
    
  }

  const handleSubmit = async () => {
    setLoad(true)
    if(!isValidTaprootAddress(json.addresses[0])){
      errorHandler("Please enter a valid taproot address")
      setLoad(false)
      return 
    }
    await addAddress({
      ...json,
    })
      .then((res) => {
        console.log('first')
        console.log(res.data)
        const address = []
        setJson({...json, addresses:['']})
        setFlag(!flag)
        successHandler('Address added successfully')
        // setLoad(false)
      })
      .catch((e) => {
        console.log('err')
        // errorHandler('createErupi failed')
        // setLoad(false)
      })
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <TextField
            name="addresses"
            value={json.addresses[0]}
            label="Enter Address"
            className={classes.textField}
            sx={{ borderRadius: '10px' }}
            fullWidth
            size="small"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          {!load ? <Button
            className="button"
            sx={{
              fontFamily: 'exo 2',
              marginLeft: 3,
              width: '80%',
              borderRadius: '10px',
            }}
            onClick={handleSubmit}
          >
            Add
          </Button> :
            <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 3, width: '100%' }}>
              <div style={{ backgroundImage: 'linear-gradient(90.35deg, #f59a6d -10.25%, #d64e5e 59.57%)', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '55%', padding: '4%' }}>
                <CircularProgress size={30} sx={{ color: 'white' }} />
              </div>
            </div>}
        </Grid>
      </Grid>
    </div>
  )
}

export default UploadManually
