import { React, useState, useEffect } from 'react'
import HeadNav from '../components/navbar/HeadNav'
import Grid from '@mui/material/Grid'
import ProjectDetails from '../components/ProjectDetails'
import FootNav from '../components/navbar/FootNav'
import SaleManagement from '../components/saleManagement/SaleManagement'
import AddWhitelist from '../components/addWhitelist/AddWhitelist'
import AirdropTokens from '../components/airdropTokens/AirdropTokens'
import SaleEnded from '../components/saleEnded/SaleEnded'
import SaleLive from '../components/saleLive/SaleLive'
import NotProjAdmin from './NotProjAdmin'
import ProjLocked from './ProjLocked'
import Typography from '@mui/material/Typography'
import { useWallet } from '../context/WalletProvider'
import { getProjectByUser } from '../services/saleServices'
import RightSide from '../components/RightSide'
import httpcommon from '../httpcommon'

const Home = () => {
  const { walletAddress } = useWallet()
  const [salePhase, setSalePhase] = useState(false)
  const [load, setLoad] = useState()
  const [rerender, setRerender] = useState()
  const verified = localStorage.getItem('verified')
  const [data, setData] = useState(null)
  // const rerender = localStorage.setItem('rerender', false)

  // const salePhase = localStorage.getItem('salePhase')
  // console.log(salePhase)

  useEffect(() => {
    async function fetchData() {
      setLoad(true)
      httpcommon.defaults.headers.common['ManagerToken'] = localStorage.getItem("managerToken");
      try {
        let res = await getProjectByUser({
          userWalletAddress: walletAddress,
        })
        setSalePhase(res.data.data.SalePhase)
        console.log('dataaaaaaaaaaaaaaaaaa', res.data.data)
        console.log('rerendering')
        setData(res.data.data)
        setLoad(false)
        // localStorage.setItem('projectId', res.data.data.ProjectID)
        // localStorage.setItem('verified', res.data.data.verified)
        // localStorage.setItem('salePhase', res.data.data.SalePhase)
      } catch (e) {
        // localStorage.setItem('salePhase', 'nosale')

        setSalePhase('nosale')
        console.log(e)
        setLoad(false)
      }
    }
    fetchData()
  }, [walletAddress, rerender])

  return (
    <>
      <HeadNav>
        {load ? (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6">Loading...</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            {walletAddress === null ? (
              <Grid
                item
                xs={12}
                sx={{
                  height: '100vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6">
                  Please connect your wallet to proceed!
                </Typography>
              </Grid>
            ) : salePhase === 'nosale' ? (
              // <Grid item xs={5}>
              <NotProjAdmin />
            ) : salePhase === 'paused' ? (
              // <Grid item xs={5}>
              <ProjLocked />
            ) : (
              <>
                <Grid item xs={7}>
                  <ProjectDetails />
                </Grid>
                <Grid item xs={5}>
                {verified === 'true' ? <RightSide salePhase={salePhase} setRerender={setRerender} data={data}  /> : 
                    <div style={{display:'flex', justifyContent:'center', height:'90vh', alignItems:'center'}}>
                    <Typography variant="p" sx={{textAlign:'center', width:'100%'}}>
                      Please wait for verification of the project from admin!
                    </Typography>
                    </div>
                  }
                </Grid>
                {/* {verified === 'true' ? (
                  salePhase === null ? (
                    <Grid item xs={5}>
                      <SaleManagement setRerender={setRerender} />
                    </Grid>
                  ) : salePhase === 'public' ? (
                    <Grid item xs={5}>
                      <SaleLive setRerender={setRerender} />
                    </Grid>
                  ) : salePhase === 'whitelist' ? (
                    <Grid item xs={5}>
                      <AddWhitelist setRerender={setRerender} />
                    </Grid>
                  ) : salePhase === 'airdrop' || salePhase === 'withdraw' ? (
                    <Grid item xs={5}>
                      <AirdropTokens setRerender={setRerender} />
                    </Grid>
                  ) : salePhase === 'ended' ? (
                    <Grid item xs={5}>
                      <SaleEnded setRerender={setRerender} />
                    </Grid>
                  ) : (
                    <Grid item xs={5}>
                      <SaleManagement setRerender={setRerender} />
                    </Grid>
                  )
                ) : (
                  // </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h6">
                      Please wait for verification of the project from admin!
                    </Typography>
                  </Grid>
                )} */}
              </>
            )}
          </Grid>
        )}
      </HeadNav>
      <FootNav />
    </>
  )
}

export default Home
