const numFormatter = (n: number) => {
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}

export function isMainnetUrl() {
  return (
    !window.location.href.includes("localhost") &&
    !window.location.href.includes("127.0.0.1:5173") &&
    !window.location.href.includes("testnet")
  );
}

export function isValidTaprootAddress(address) {
  const isMainnet = isMainnetUrl();
  const taprootRegex = isMainnet
    ? /^bc1[0-9a-zA-Z]{59}$/
    : /^tb1[0-9a-zA-Z]{59}$/;
  return taprootRegex.test(address);
}

const formatDate = (date: string) => {
  const dateObj = new Date(date)
  //   const options: any = {
  //     hour: "numeric",
  //     minute: "numeric",
  //     hour12: true,
  //     day: "numeric",
  //     month: "short",
  //     year: "numeric"
  //   }
  //   const formattedDate = dateObj.toLocaleDateString("en-US", options)
  const hour = dateObj.getHours()
  const minute = dateObj.getMinutes()
  const ampm = hour >= 12 ? 'PM' : 'AM'
  const formattedHour = hour % 12 || 12

  const formattedDate = `${formattedHour}:${
    minute < 10 ? '0' + minute : minute
  } ${ampm}, ${dateObj.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })}`

  return formattedDate
}

const formatTimeAgo = (date: Date): string => {
  const currentDate: Date = new Date()
  // @ts-ignore
  const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000)

  const intervals: Record<string, number> = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  }

  let timeAgo: string | undefined

  for (const [unit, secondsInUnit] of Object.entries(intervals)) {
    const intervalCount = Math.floor(timeDifferenceInSeconds / secondsInUnit)

    if (intervalCount > 0) {
      timeAgo = `${intervalCount} ${unit}${intervalCount > 1 ? 's' : ''} ago`
      break
    }
  }

  return timeAgo || 'Just now'
}

const formatWalletAddress = (
  address: string | any[],
  prefixLength?: number | undefined,
  suffixLength?: number | undefined
) => {
  prefixLength = prefixLength || 6
  suffixLength = suffixLength || 5

  const prefix = address.slice(0, prefixLength)
  const suffix = address.slice(-suffixLength)
  const formattedAddress = `${prefix}... ${suffix}`

  return formattedAddress
}

const capitalizedLetter = (letter: string) => {
  return letter.charAt(0).toUpperCase() + letter.slice(1)
}

const isURL = (str: string) => {
  const pattern = new RegExp(
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
  )
  return pattern.test(str)
}

const roundToFourDecimalPlaces = (number: number, decimalPoints = 4) => {
  if (isNaN(number)) {
    console.error('Invalid number. Please provide a valid number.')
    return null
  }

  const roundedValue = parseFloat(Number(number).toFixed(decimalPoints))
  return roundedValue
}

const roundToNearestWholeNumber = (floatValue: number) => {
  const decimalPart = floatValue - Math.floor(floatValue)
  const roundedValue =
    decimalPart > 0.5 ? Math.ceil(floatValue) : Math.floor(floatValue)
  return roundedValue
}

export {
  numFormatter,
  formatDate,
  formatTimeAgo,
  formatWalletAddress,
  capitalizedLetter,
  isURL,
  roundToFourDecimalPlaces,
  roundToNearestWholeNumber,
}
