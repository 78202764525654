import { React, useState, useEffect } from 'react'
import { Grid, Button, Typography, Box, Stepper, Step, StepLabel, CircularProgress } from '@mui/material'
import Divider from '@mui/material/Divider'
import Table from '../saleLive/Table'
import {
  airdropTokenCreate,
  getInscriptionStatus,
  airdropTokenSign,
  airdropTokenExecute,
  doTransaction,
  generateTransaction,
  tokenCreate,
  tokenPaid,
  tokenSign,
  tokenExecute,
  getSalesProgress,
} from '../../services/saleServices'
import { successHandler, errorHandler } from '../../utils/toasts'
import {
  getDynamicFee
} from '../../services/saleServices'
import { useNavigate } from 'react-router-dom'

const AirdropTokens = ({ setRerender, data }) => {
  const [tokenExec, setTokenExec] = useState()
  const [publicKey, setPublicKey] = useState()
  const [display, setDisplay] = useState('none')
  const [feeRate, setFeeRate] = useState()
  const [activeStep, setActiveStep] = useState(data?.inscriptionId ? 1 : -1)
  const [load, setLoad] = useState(false)
  const [show, setShow] = useState(data?.inscriptionId ? true : false)
  const navigate = useNavigate()
  const [withdrawloading, setWithdrawLoading] = useState(false)
  var salePhase = localStorage.getItem('salePhase')
  const [saleProgress, setSaleProgress] = useState(0)

  // const { walletAddress } = useWallet()

  useEffect(() => {
    async function fetchData() {
      console.log('here')
      const network = await window.unisat.getNetwork()
        const response = await getDynamicFee()
        const data = response.data
        setFeeRate(data?.fee + 1)
    }

    fetchData()
  }, [])


  useEffect(() => {
    async function fetchData() {
      try {
        let res = await getSalesProgress({
          projectId: Number(localStorage.getItem('projectId')),
        })
        setSaleProgress(res.data.totalBalance)
        console.log(res.data)
      } catch (e) {
        console.log(e)
      }
    }
    const intervalId = setInterval(() => {
      fetchData()
    }, 1000 * 5) // in milliseconds
    return () => clearInterval(intervalId)
    // fetchData()
  }, [])


  // const handleClick = async () => {
  //   setLoad(true)
  //   airdropTokenCreate({
  //     projectId: Number(localStorage.getItem('projectId')),
  //   })
  //     .then(async (res) => {
  //       console.log('first')
  //       const data = res.data
  //       console.log(res.data)

  //       try {
  //         let txid = await window.unisat.sendBitcoin(
  //           data?.payAddress,
  //           data?.amount,
  //           { feeRate }
  //         )
  //         console.log(txid)
  //         var refreshId = setInterval(async () => {
  //           const res = await getInscriptionStatus(data?.orderId)
  //           console.log(res.data)
  //           if (res.data?.status !== 'pending') {
  //             const resp = await airdropTokenSign({
  //               inscriptionId: res.data?.inscription,
  //               projectId: Number(localStorage.getItem('projectId')),
  //             })
  //             console.log(resp.data)
  //             setActiveStep(1)
  //             // setTokenExec(resp.data)
  //             clearInterval(refreshId)
  //             const psbtHex = await window.unisat.signPsbt(resp.data?.psbt, {
  //               autoFinalized: false,
  //               toSignInputs: resp.data?.inputs.map((inp, index) => ({
  //                 index,
  //                 publicKey: publicKey,
  //                 // publicKey: '036645e4d39f0c3c873f804f262e227076329e8e3a32ae79e055174e0c4a72a98f',
  //                 disableTweakSigner: true,
  //               })),
  //             })
  //             const resTokenExec = await airdropTokenExecute({
  //               psbtHex,
  //               inputs: resp.data?.inputs,
  //               projectId: Number(localStorage.getItem('projectId')),
  //             })
  //             console.log(resTokenExec.data)
  //             console.log('b4 rerender')
  //             setRerender('transferred')
  //             localStorage.setItem('salePhase', 'withdraw')
  //             console.log('after rerender at airdroptokens')
  //             successHandler('Airdrop Token successful')
  //             setActiveStep(2)
  //             setLoad(false)
  //           }
  //         }, '3000')
  //         clearInterval(refreshId);
  //       } catch (error) {
  //         console.log(error)
  //         clearInterval(refreshId)
  //         setLoad(false)
  //       }
  //     })
  //     .catch((e) => {
  //       console.log('err')
  //       console.log(e)
  //       errorHandler('Error while airdropping tokens')
  //       // setDisplay('block')
  //       setLoad(false)
  //     })
  // }

  useEffect(() => {
    async function fetchData() {
      try {
        let res = await window.unisat.getPublicKey()
        setPublicKey(res)
      } catch (e) {
        console.log(e)
      }
    }
    fetchData()
  }, [])

  useEffect(() => { }, [load])

  const handleClickWithdraw = async () => {
    setWithdrawLoading(true)
    try {
      let res = await generateTransaction({
        projectId: Number(localStorage.getItem('projectId')),
      })
      const psbtHex = await window.unisat.signPsbt(res.data?.psbt, {
        autoFinalized: false,
        toSignInputs: res.data?.inputs.map((inp, index) => ({
          index,
          publicKey: publicKey,
          disableTweakSigner: true,
        })),
      })
      const resDoTransac = await doTransaction({
        psbtHex,
        inputs: res.data?.inputs,
        projectId: Number(localStorage.getItem('projectId')),
      })
      setRerender('withdrawn')
      successHandler('Withdraw successful')
      console.log('here')
      setWithdrawLoading(false)
      localStorage.setItem('salePhase', 'ended')
    } catch (error) {
      console.log(error)
      errorHandler('Withdraw unsuccessful')
      setWithdrawLoading(false)
    }
  }

  const steps = [
    'Create Token Inscription',
    'Send Inscription'
  ];

  console.log(data?.ProjectID, 'data')

  const create = () => {
    setLoad(true)
    tokenCreate(data?.ProjectID)
      .then(async (res) => {
        console.log(res)
        try {
          if (res.data?.success !== false) {
            let txid = await window.unisat.sendBitcoin(
              res.data?.payAddress,
              res.data?.amount,
              { feeRate }
            )
            console.log(txid)
            tokenPaid({ projectId: data?.ProjectID, creationTxHash: txid })
              .then((res) => {
                console.log(res)
                setShow(true)
                setActiveStep(1)
                setLoad(false)
                successHandler('Successful, Please try sending inscription after some time')
              }).catch((error) => {
                errorHandler(error?.message)
                setLoad(false)
                console.log(error)
              })
          }else{
            setRerender('withdrawn')
            localStorage.setItem('salePhase', 'ended')
            errorHandler(res.data?.message)

          }
        } catch (error) {
          setLoad(false)
          errorHandler(error?.message)
          console.log(error)
        }
      }).catch((error) => {
        setLoad(false)
        errorHandler(error?.message)
        console.log(error)
      })
  }

  const send = () => {
    setLoad(true)
    tokenSign(data?.ProjectID)
      .then(async (res) => {
        console.log(res)
        try {
          const psbtHex = await window.unisat.signPsbt(res.data?.psbt, {
            autoFinalized: false,
            toSignInputs: res.data?.inputs.map((inp, index) => ({
              index,
              publicKey: publicKey,
              // publicKey: '036645e4d39f0c3c873f804f262e227076329e8e3a32ae79e055174e0c4a72a98f',
              disableTweakSigner: true,
            }))
          })

          tokenExecute({ projectId: data?.ProjectID, psbtHex: psbtHex, inputs: res.data?.inputs })
            .then((res) => {
              console.log(res)
              localStorage.setItem('salePhase', 'withdraw')
              console.log('b4 rerender')
              setRerender('transferred')
              console.log('after rerender at airdroptokens')
              successHandler('Airdrop Token successful')
              setActiveStep(2)
              setLoad(false)
            }).catch((err) => {
              console.log(err)
              errorHandler(err?.message)
              setLoad(false)
            })

        } catch (error) {
          console.log(error)
          errorHandler(error?.message)
          setLoad(false)
        }
      }).catch((err) => {
        console.log(err)
        errorHandler(err?.message)
        setLoad(false)
      })
  }

  return (
    <div>
      <Grid container spacing={2} sx={{ paddingRight: 5 }}>
        {salePhase === 'withdraw' ? <Grid item xs={12}><Button
            sx={{
              fontFamily: 'Exo 2',
              // height: '60%',
              color: '#fff',
              marginRight: 3,
              // width: '80%',
              borderRadius: '10px',
              border: '1px solid #566077',
            }}
          >
            FUND Raised {saleProgress ? (saleProgress / 100000000) : 0} BTC
          </Button> </Grid> : !load ? !show ? <Grid item xs={6}>
          <Button
            className="button"
            sx={{
              fontFamily: 'exo 2',
              marginTop: '3',
              width: '80%',
              borderRadius: '10px',
            }}

            onClick={create}
          >
            Create Inscription
          </Button>
        </Grid> : <Grid item xs={6}>
          <Button
            className="button"
            sx={{
              fontFamily: 'exo 2',
              marginTop: '3',
              width: '80%',
              borderRadius: '10px',
            }}

            onClick={send}
          >
            Send Inscription
          </Button>
        </Grid> : <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', marginTop: '3' }}>
          <div style={{ backgroundImage: 'linear-gradient(90.35deg, #f59a6d -10.25%, #d64e5e 59.57%)', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '55%', padding: '4%' }}>
            <CircularProgress size={30} sx={{ color: 'white' }} />
          </div>
        </Grid>}
        <Grid
          item
          xs={12}
          align="center"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {salePhase === 'withdraw' ? "" : <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} sx={{
              '& .MuiSvgIcon-root': {
                bgcolor: '#FA7C0A24', color: 'transparent', borderRadius: '5px', border: '1px solid #32201E',
                '&.Mui-completed': { color: '#287B5386 !important', bgcolor: 'transparent', border: 'none' },
                '&.Mui-active': { bgcolor: '#FA7C0A24' }
              }
            }} >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel sx={{ '& .MuiStepLabel-label': { color: '#566077 !important' } }}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>}
        </Grid>
        <Grid item xs={12}>
          <Table />
        </Grid>
        {salePhase === 'withdraw' ? !withdrawloading ? (
          <Grid item xs={12}>
            <Button
              className="button"
              sx={{
                fontFamily: 'exo 2',
                marginLeft: 3,
                // height: '80%',
                width: '90%',
                borderRadius: '10px',
                // display: salePhase === 'withdraw' ? 'block' : 'none'
              }}
              onClick={handleClickWithdraw}
            >
              Withdraw
            </Button>
          </Grid>
        ) : <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ backgroundImage: 'linear-gradient(90.35deg, #f59a6d -10.25%, #d64e5e 59.57%)', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '55%', padding: '1%' }}>
            <CircularProgress size={30} sx={{ color: 'white' }} />
          </div>
        </Grid> : (
          ''
        )}
      </Grid>
    </div>
  )
}

export default AirdropTokens
