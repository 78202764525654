import React from 'react'
import { Box, Paper, CardMedia } from '@mui/material'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import HomeIcon from '@mui/icons-material/Home'
import SubjectIcon from '@mui/icons-material/Subject'
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic'
// import mic from '../../images/mic.png'
import { useNavigate } from 'react-router-dom'

export default function FootNav() {
  const [value, setValue] = React.useState(0)
  const url = window.location.href.split('/')[3]
  console.log(url)
  const navigate = useNavigate()

  return (
    <div style={{ padding: '2em', backgroundColor: '#000000' }}>Footer</div>
    // <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
    //     <BottomNavigation
    //       showLabels
    //       value={value}
    //       onChange={(event, newValue) => {
    //         setValue(newValue);
    //       }}
    //     >
    //       <BottomNavigationAction label="Home" sx={url === '' ?   {'.Mui-selected' : {color:'#166832'}} : {'.Mui-selected' : {color:'rgba(0, 0, 0, 0.6)', fontSize:'0.75rem'}}} onClick={() => navigate('/')}  icon={<HomeIcon sx={url === '' ?   {color:'#166832'} : {color:'rgba(0, 0, 0, 0.6)'}} />} />
    //       {/* <BottomNavigationAction label="" icon={<CardMedia component='img' src={mic}  sx={   {color:'#166832',width:'40px', marginTop:'-50px', bgcolor:'white', padding:'10px', borderRadius:'50px',boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'} } />} /> */}
    //       <BottomNavigationAction onClick={() => navigate('/schemes')} label="Schemes" sx={url === 'schemes' ?   {'.Mui-selected' : {color:'#166832', fontSize:'1rem'}} : {'.Mui-selected' : {color:'rgba(0, 0, 0, 0.6)', fontSize:'0.75rem'}}} icon={<AutoAwesomeMosaicIcon sx={url === 'schemes' ?   {color:'#166832'} : {}} />} />
    //     </BottomNavigation>
    //   </Paper>
  )
}
