import axios from "axios";
import { isMainnetUrl } from "./utils/helpers";

const isMainnet = isMainnetUrl()

export default axios.create({
  baseURL: isMainnet ? `https://app.tapstarter.io/api/v1` : `https://testnet.tapstarter.io/api/v1`,

  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  mode: 'cors',
})